import React, { createContext, useEffect, useState } from 'react';
import { User } from 'firebase/auth';
import { auth, db } from 'services/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { UserType } from 'types/user';

type ContextProps = {
  loadingAuthState?: boolean;
  userDataLoadingState?: boolean;
  user?: User | null;
  userData?: UserType;
};

type Props = {
  children: React.ReactNode;
};

export const AuthContext = createContext<ContextProps>({ loadingAuthState: false, user: null });

export const AuthProvider = ({ children }: Props) => {
  const [user, loading, error] = useAuthState(auth);
  const [userData, setUserData] = useState<UserType>();
  const [userDataLoading, setUserDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const getUserData = async () => {
      if (user && !loading && !error) {
        setUserDataLoading(true);
        const documents = await getDoc(doc(db, 'users', user?.uid));
        setUserData(documents.data() as UserType);
        setUserDataLoading(false);
      }
    };
    getUserData();
  }, [user, loading, error]);

  return (
    <AuthContext.Provider
      value={{
        loadingAuthState: loading,
        userDataLoadingState: userDataLoading,
        user,
        userData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
