import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

type SideTabProps = {
  children?: React.ReactNode;
  isOpen?: boolean;
  additionalClass?: string;
};

const SideTab: React.FC<SideTabProps> = ({ children, isOpen, additionalClass }) => {
  return <div className={classNames(styles.sideTab, additionalClass, { [styles.open]: isOpen })}>{children}</div>;
};

export default SideTab;
