import { toast } from 'react-toastify';

const notify = (contents: string) =>
  toast(contents, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });

export { notify };
