import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth';

import { auth } from 'services/firebase';
import { emailChecker } from 'services/validate';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import Wrapper from 'components/Wrapper';
import styles from './styles.module.scss';
import { notify } from 'services/notify';

const ResetPassword = () => {
  const [sendPasswordResetEmail, sending, error] = useSendPasswordResetEmail(auth);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit(async data => {
    await sendPasswordResetEmail(data.email);
    if (error) {
      notify(error.message);
    }
  });

  useEffect(() => {
    if (!sending && error) notify(error.message);
  }, [sending]);

  return (
    <div className={styles.wrapper}>
      <form onSubmit={onSubmit} className={styles.formControl}>
        <Wrapper className={styles.inputWrapper}>
          <TextInput
            className="text-center"
            errorMsg={errors.email}
            placeHolder="EMAIL"
            register={register}
            required={true}
            type="text"
            variableName="email"
            validate={(value: string) => {
              return emailChecker(value) || 'Please enter valid email address';
            }}
          />
          <Button
            label="Passwort zurücksetzen"
            className={styles.submitButton}
            labelClassName={styles.submitLabel}
            type="submit"
            isLoading={sending}
            disabled={sending}
          />
          <div className={styles.linkWrapper}>
            <Link to="/auth/" className={styles.link}>
              Zurück zum Login
            </Link>
          </div>
        </Wrapper>
      </form>
    </div>
  );
};

export default ResetPassword;
