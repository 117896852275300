import React from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  disabled?: boolean;
  errorMsg?: any;
  name: string;
  placeholder?: string;
  prefix: string;
  register?: UseFormRegister<FieldValues>;
  required?: boolean;
  type?: string;
  validate?: any;
  value?: string;
  onChange?: (value: any) => void;
};

const TextInputWithPrefix: React.FC<Props> = ({
  className = '',
  disabled = false,
  errorMsg,
  name,
  placeholder = '',
  prefix = '0',
  register,
  required = false,
  type = 'text',
  validate,
  value,
  onChange = () => {},
}) => {
  const isControlled = value !== undefined && onChange !== undefined;
  return (
    <div className={className}>
      <div className={styles.contents}>
        <div className={styles.prefixWrapper}>{prefix}:</div>
        <div className={styles.inputWrapper}>
          <input
            className={styles.input}
            {...(isControlled ? { value } : { defaultValue: value })}
            disabled={disabled}
            name={name}
            placeholder={placeholder}
            type={type}
            {...(register && register(name, { required: required ? 'This field is required' : false, validate }))}
            onChange={e => {
              onChange(e.target.value);
            }}
            required={required}
          />
        </div>
      </div>
      {errorMsg && <div className={styles.errorMsg}>{errorMsg.message}</div>}
    </div>
  );
};

export default TextInputWithPrefix;
