import classNames from 'classnames';
import React from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  error?: any;
  label?: string;
  name?: string;
  required?: boolean;
  size?: 'large' | 'small';
  type?: 'checkbox' | 'radio';
  value?: string;
  checked?: boolean;
  register?: UseFormRegister<FieldValues>;
  onChange?: (checked: boolean, val: string) => void;
};

const CheckBox: React.FC<Props> = ({
  className = '',
  error,
  label = '',
  name = '',
  required = false,
  size = 'small',
  type = 'checkbox',
  value = '',
  register,
  checked = false,
  onChange = () => {},
}) => {
  const getChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked, e.target.value);
  };
  return (
    <div className={classNames(styles.wrapper, className)}>
      <label className={styles.label}>
        <input
          type={type}
          name={name}
          value={value}
          checked={checked}
          {...(register && register(name, { required: required }))}
          className={classNames(
            styles.checkbox,
            { [styles.error]: error },
            { [styles.large]: size === 'large' },
            { [styles.small]: size === 'small' }
          )}
          onChange={getChange}
        />
        <span>{label}</span>
      </label>
    </div>
  );
};

export default CheckBox;
