import React, { useState, useEffect, useContext } from 'react';
import { doc, updateDoc, onSnapshot, Timestamp, collection, getDocs } from 'firebase/firestore';

import Table from 'components/Table';
import ContainerWrapper from 'components/ContainerWrapper';
import { db } from 'services/firebase';
import { AuthContext } from 'providers/Auth';
import { UserType } from 'types/user';
import styles from './styles.module.scss';
import { Address } from 'types/address';
import { ScheduleType } from 'types/schedule';
import classNames from 'classnames';
import moment from 'moment';
import UserInteractions from 'components/UserInteractions';
import { notify } from 'services/notify';
import Button from 'components/Button';

const PromoterWeekOverView = () => {
  const { userData } = useContext(AuthContext);
  const [scheduledAdd, setScheduledAdd] = useState<Array<ScheduleType>>([]);
  const [visitDate, setVisitDate] = useState<Date>('' as any);
  const [selectedItem, setSelectedItem] = useState<Address | null>(null);
  const [showCheckmark, setShowCheckmark] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [storedSchedule, setStoredSchedule] = useState<Array<ScheduleType>>([]);

  const [currentWeek, setCurrentWeek] = useState(moment().isoWeek());
  const currentYear = moment().year();
  const firstDayOfWeek = moment().isoWeekYear(currentYear).isoWeek(currentWeek).startOf('isoWeek');
  const lastDayOfWeek = moment().isoWeekYear(currentYear).isoWeek(currentWeek).endOf('isoWeek');
  const filteredSchedule = scheduledAdd?.filter(elem => {
    if (!elem.date) {
      return false;
    }
    return moment(elem.date?.toDate()) >= firstDayOfWeek && moment(elem.date?.toDate()) <= lastDayOfWeek;
  });
  const daysOfWeek = [
    firstDayOfWeek,
    firstDayOfWeek.clone().add(1, 'days'),
    firstDayOfWeek.clone().add(2, 'days'),
    firstDayOfWeek.clone().add(3, 'days'),
    firstDayOfWeek.clone().add(4, 'days'),
  ];

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'scheduled', (userData as UserType).uid), data => {
      const fetchedData = (data.data()?.assigned as Array<ScheduleType>) ?? [];
      setScheduledAdd(fetchedData);
      setStoredSchedule(fetchedData);
    });
    return unsubscribe;
  }, []);

  const addSelectedToSchedule = async (date: Date) => {
    if (selectedItem) {
      const existingScheduleIndex = storedSchedule.findIndex(sche => sche.address.docId === selectedItem.docId);
      const scheduleExist = storedSchedule.filter(sche => sche.address.docId === selectedItem.docId).length > 0;
      setIsLoading(true);
      if (scheduleExist) {
        // Update the date of the existing schedule
        storedSchedule[existingScheduleIndex].date = Timestamp.fromDate(date);
        await updateDoc(doc(db, 'scheduled', `${(userData as UserType).uid}`), {
          assigned: storedSchedule,
        });
      } else {
        await updateDoc(doc(db, 'scheduled', `${(userData as UserType).uid}`), {
          assigned: [...storedSchedule, { date: Timestamp.fromDate(date), address: selectedItem }],
        });
      }
      setIsLoading(false);
      setShowCheckmark(true);
      setTimeout(() => setShowCheckmark(false), 2000); // hide after 2 seconds
    } else {
      notify('No Address to assign');
    }
  };

  const getDateAssignedToItem = (docId: string): Date | null => {
    const item = storedSchedule.find(sche => sche.address.docId === docId);
    if (item && item.date) {
      const date = item.date instanceof Timestamp ? item.date.toDate() : item.date;
      return date instanceof Date ? date : null;
    }
    return null;
  };

  useEffect(() => {
    if (selectedItem) {
      const dateAssigned = getDateAssignedToItem(selectedItem.docId);
      if (dateAssigned) {
        setVisitDate(dateAssigned);
      } else {
        setVisitDate('' as any);
      }
    }
  }, [selectedItem, storedSchedule]);

  return (
    <>
      <ContainerWrapper className={styles.wrapper}>
        <div className={styles.tableWrapper}>
          <Table
            className={classNames(styles.table)}
            title={
              <div className="flex gap-20 pb-10">
                <p>KW {currentWeek}</p>
                <Button
                  label="zur aktuellen Woche"
                  className=" font-univers-std text-18 text-white bg-primary px-20 pt-10 pb-5"
                  onClick={() => setCurrentWeek(moment().isoWeek())}
                />
              </div>
            }
            head={[
              `Montag${daysOfWeek[0].format(', D.M.')}`,
              `Dienstag${daysOfWeek[1].format(', D.M.')}`,
              `Mittwoch${daysOfWeek[2].format(', D.M.')}`,
              `Donnerstag${daysOfWeek[3].format(', D.M.')}`,
              `Freitag${daysOfWeek[4].format(', D.M.')}`,
            ]}
          >
            <tr className={classNames(styles.tableButtonWrapper, styles.tableButtonLeft)}>
              <td>
                <button className={styles.tableButton} onClick={() => setCurrentWeek(currentWeek - 1)}>
                  <svg
                    id="Gruppe_10"
                    data-name="Gruppe 10"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.77"
                    height="36"
                    viewBox="0 0 16.77 36"
                  >
                    <g id="Gruppe_6" data-name="Gruppe 6" transform="translate(0 0)">
                      <path
                        id="Pfad_4"
                        data-name="Pfad 4"
                        d="M16.77,0l-7.7,18.144L16.77,36H10.636L0,18.144,10.636,0Z"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                </button>
              </td>
            </tr>
            <tr className={classNames(styles.tableButtonWrapper, styles.tableButtonRight)}>
              <td>
                <button className={styles.tableButton} onClick={() => setCurrentWeek(currentWeek + 1)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16.77" height="36" viewBox="0 0 16.77 36">
                    <g id="Gruppe_9" data-name="Gruppe 9" transform="translate(0 120)">
                      <g id="Gruppe_6" data-name="Gruppe 6" transform="translate(0 -120)">
                        <path
                          id="Pfad_4"
                          data-name="Pfad 4"
                          d="M0,0,7.7,18.144,0,36H6.134L16.77,18.144,6.134,0Z"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </svg>
                </button>
              </td>
            </tr>
            <tr>
              {daysOfWeek.map((day, indextd) => (
                <td valign="top" key={day.toString()} className={styles.td}>
                  <div className={classNames(styles.elementWrapper, { [styles.first]: indextd === 0 })}>
                    {filteredSchedule
                      ?.filter(sche => {
                        if (!sche.date) {
                          return false;
                        }
                        return day.isSame(moment(sche.date.toDate()), 'days');
                      })
                      ?.map((item, index) => (
                        <div
                          className={classNames(styles.element, {
                            [styles.done]: item.address?.complete,
                          })}
                          onClick={() => {
                            setOpenModal(true);
                            setSelectedItem(item.address);
                          }}
                        >
                          <p className={styles.text}>{item.address.CompanyName}</p>
                          <p className={classNames(styles.text, styles.smallText)}>{item.address.Address1}</p>
                          <p
                            className={classNames(styles.text, styles.smallText)}
                          >{`${item.address.PostalCode} ${item.address.City}`}</p>
                        </div>
                      ))}
                  </div>
                </td>
              ))}
            </tr>
          </Table>
        </div>
      </ContainerWrapper>
      <UserInteractions
        openModal={openModal}
        selectedItem={selectedItem}
        setOpenModal={setOpenModal}
        showCheckmark={showCheckmark}
        addSelectedToSchedule={addSelectedToSchedule}
        visitDate={visitDate}
        isAgency={false}
        setVisitDate={setVisitDate}
      />
    </>
  );
};

export default PromoterWeekOverView;
