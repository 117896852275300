import React, { useContext, useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';

import Table from 'components/Table';
import ContainerWrapper from 'components/ContainerWrapper';
import { VisitLogType } from 'types/visitlog';
import { db } from 'services/firebase';
import { AuthContext } from 'providers/Auth';
import styles from './styles.module.scss';
import moment from 'moment';

const AllVisitLogs = () => {
  const [visitLogs, setVisitLogs] = useState<Array<VisitLogType>>([]);
  const { userData } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getVisitedLog = async () => {
      const documents = await getDocs(
        query(collection(db, 'visit'), where('userId', '==', userData?.uid), where('address.docId', '==', id))
      );
      const data = documents.docs;
      setVisitLogs(
        data.map(record => {
          return { uid: record.id, ...record.data() } as VisitLogType;
        })
      );
    };
    getVisitedLog();
  }, []);

  return (
    <ContainerWrapper className={styles.wrapper}>
      <div className={styles.header}>
        <span className={styles.title}>Alle Besuchsprotokolle</span>
      </div>
      <div className={styles.body}>
        <Table
          title=""
          head={[
            'Firma',
            'Straße und Hausnummer',
            'Zusatz',
            'PLZ',
            'Ort',
            'Telefon',
            'Datum',
            'Uhrzeit',
            'Besuch fand statt',
          ]}
        >
          {visitLogs?.map((record, index) => (
            <tr key={index} onClick={() => navigate(`/promoter/planning/visit/logs/details/${record.uid}`)}>
              <td>{record.address.CompanyName}</td>
              <td>{record.address.Address1}</td>
              <td>{record.address.Address2}</td>
              <td>{record.address.PostalCode}</td>
              <td>{record.address.City}</td>
              <td>{record.address.BusinessPhoneNumber}</td>
              <td>{moment(record.visitedAt.date).format('DD.MM.YY')}</td>
              <td>{record.visitedAt.time}</td>
              <td>{record.isVisited === 'VISITED' ? 'Ja' : 'Nein'}</td>
            </tr>
          ))}
        </Table>
      </div>
    </ContainerWrapper>
  );
};

export default AllVisitLogs;
