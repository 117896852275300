import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

import Button from 'components/Button';
import TextInputWithPrefix from 'components/TextInputWithPrefix';
import styles from './styles.module.scss';
import { db } from 'services/firebase';
import { Address } from 'types/address';
import ContainerWrapper from 'components/ContainerWrapper';
import { useForm } from 'react-hook-form';
import { notify } from 'services/notify';
import { UserType } from 'types/user';
import { AuthContext } from 'providers/Auth';
import { ScheduleType } from 'types/schedule';

const ViewShop = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [address, setAddress] = useState<Address>();
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, setValue } = useForm();
  const [scheduled, setScheduled] = useState<Array<ScheduleType>>([]);

  //get adress
  useEffect(() => {
    const getAddressDetail = async () => {
      if (!id) {
        navigate('/promoter/planning/weekoverview');
      } else {
        const addressData = await getDoc(doc(db, 'address', id));
        const data = addressData.data();
        setAddress(data as Address);
      }
    };
    getAddressDetail();
  }, []);

  //set address attributes in form
  useEffect(() => {
    setValue('CompanyName', address?.CompanyName || '');
    setValue('Address1', address?.Address1 || '');
    setValue('Address2', address?.Address2 || '');
    setValue('City', address?.City || '');
    setValue('PostalCode', address?.PostalCode || '');
    setValue('BusinessPhoneNumber', address?.BusinessPhoneNumber || '');
    setValue('Emailaddress', address?.Emailaddress || '');
  }, [address]);

  //get schedule
  useEffect(() => {
    const getScheduled = async () => {
      const documents = await getDoc(doc(db, 'scheduled', (userData as UserType).uid));
      const data = (documents.data()?.assigned as Array<ScheduleType>) ?? [];
      setScheduled(data);
    };
    getScheduled();
  }, []);

  const onSubmit = handleSubmit(async data => {
    if (address) {
      try {
        setIsLoading(true);
        //update in adress
        await updateDoc(doc(db, 'address', id as string), {
          ...data,
          updatedByPromoter: true,
          updatedByPromoterId: (userData as UserType).uid,
        });

        //update in scheduled
        scheduled.forEach(current => {
          if (current?.address.docId == id) {
            //for every attribute stored in adress update in current schedule item
            Object.keys(data).forEach(key => {
              (current.address as any)[key] = data[key];
            });
            current.address.updatedByPromoter = true;
            current.address.updatedByPromoterId = (userData as UserType).uid;
          }
        });
        await updateDoc(doc(db, 'scheduled', `${(userData as UserType).uid}`), {
          assigned: scheduled,
        });

        setIsLoading(false);
      } catch (error) {
        notify('Failed');
      }
    }
  });

  return (
    <ContainerWrapper className={styles.wrapper}>
      <div className={styles.headWrapper}>
        <form onSubmit={onSubmit}>
          <div className={styles.formWrapper}>
            <div className={styles.grid12}>
              <div className={styles.inputSpan8}>
                <p className={styles.title}>Stammdaten</p>
                <TextInputWithPrefix name="CompanyName" prefix="Firma" disabled={false} register={register} />
              </div>
            </div>
            <div className={styles.grid12}>
              <TextInputWithPrefix
                name="Address1"
                prefix="Straße und Hausnummer"
                register={register}
                className={styles.inputSpan8}
                disabled={false}
              />
            </div>
            <div className={styles.grid12}>
              <TextInputWithPrefix
                name="Address2"
                prefix="Zusatz"
                className={styles.inputSpan5}
                register={register}
                disabled={false}
              />
              <TextInputWithPrefix
                name="PostalCode"
                prefix="PLZ"
                className={styles.inputSpan2}
                register={register}
                disabled={false}
              />
              <TextInputWithPrefix
                name="City"
                prefix="Ort"
                className={styles.inputSpan5}
                register={register}
                disabled={false}
              />
            </div>
            <div className={styles.grid12}>
              <TextInputWithPrefix
                name="BusinessPhoneNumber"
                prefix="Telefon"
                className={styles.inputSpan4}
                register={register}
                disabled={false}
              />
              <TextInputWithPrefix
                name="Emailaddress"
                prefix="E-Mail"
                className={styles.inputSpan8}
                register={register}
                disabled={false}
              />
            </div>
          </div>
          <div className={styles.buttonsWrapper}>
            {' '}
            <Button
              className={styles.button}
              color="secondary"
              label="Zurück"
              type="button"
              disabled={false}
              onClick={() => navigate('/promoter/planning/address')}
            />
            <Button
              className={styles.button}
              color="secondary"
              label="Speichern"
              type="submit"
              isLoading={isLoading}
              disabled={isLoading}
            />
          </div>
        </form>
      </div>
    </ContainerWrapper>
  );
};

export default ViewShop;
