import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import ContainerWrapper from 'components/ContainerWrapper';
import Table from 'components/Table';
import moment from 'moment';
import styles from './styles.module.scss';
import { MainContext } from 'providers/Main';

const AllVisitLogs = () => {
  const { id } = useParams();
  const { visitLogItems } = useContext(MainContext);

  return (
    <ContainerWrapper className={styles.wrapper}>
      <div className={styles.header}></div>
      <div className={styles.body}>
        <Table
          title="Alle Besuchsprotokolle"
          head={[
            'Firma',
            'Straße und Hausnummer',
            'Zusatz',
            'PLZ',
            'Ort',
            'Telefon',
            'Datum',
            'Uhrzeit',
            'Besuch fand statt',
          ]}
        >
          {visitLogItems
            .filter(item => item.address.docId === id)
            ?.map((record, index) => (
              <tr key={index}>
                <td>{record.address.CompanyName}</td>
                <td>{record.address.Address1}</td>
                <td>{record.address.Address2}</td>
                <td>{record.address.PostalCode}</td>
                <td>{record.address.City}</td>
                <td>{record.address.BusinessPhoneNumber}</td>
                <td>{moment(record.visitedAt.date).format('DD.MM.YY')}</td>
                <td>{record.visitedAt.time}</td>
                <td>{record.isVisited === 'VISITED' ? 'Ja' : 'Nein'}</td>
              </tr>
            ))}
        </Table>
      </div>
    </ContainerWrapper>
  );
};

export default AllVisitLogs;
