import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';

import Button from 'components/Button';
import TextInputWithPrefix from 'components/TextInputWithPrefix';
import styles from './styles.module.scss';
import { db } from 'services/firebase';
import { Address } from 'types/address';
import ContainerWrapper from 'components/ContainerWrapper';

const ViewShop = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [address, setAddress] = useState<Address>();

  useEffect(() => {
    const getAddressDetail = async () => {
      if (!id) {
        navigate('/promoter/planning/weekoverview');
      } else {
        const addressData = await getDoc(doc(db, 'address', id));
        const data = addressData.data();
        setAddress(data as Address);
      }
    };
    getAddressDetail();
  }, []);

  const linkTo = (where: string, param?: string) => {
    navigate(`/agency/planning/${where}/${param}`);
  };

  return (
    <ContainerWrapper className={styles.wrapper}>
      <div className={styles.headWrapper}>
        <form>
          <div className={styles.formWrapper}>
            <div className={styles.grid12}>
              <div className={styles.inputSpan8}>
                <p className={styles.title}>Stammdaten</p>
                <TextInputWithPrefix prefix="Firma" name="companyName" value={address?.CompanyName} disabled />
              </div>
            </div>
            <div className={styles.grid12}>
              <TextInputWithPrefix
                name="address1"
                prefix="Straße und Hausnummer"
                className={styles.inputSpan8}
                value={address?.Address1}
                disabled
              />
            </div>
            <div className={styles.grid12}>
              <TextInputWithPrefix
                prefix="Zusatz"
                className={styles.inputSpan5}
                value={address?.Address2}
                name="address2"
                disabled
              />
              <TextInputWithPrefix
                prefix="PLZ"
                className={styles.inputSpan2}
                value={address?.PostalCode}
                name="postCode"
                disabled
              />
              <TextInputWithPrefix
                prefix="Ort"
                className={styles.inputSpan5}
                value={address?.City}
                name="city"
                disabled
              />
            </div>
            <div className={styles.grid12}>
              <TextInputWithPrefix
                name="phoneNumber"
                prefix="Telefon"
                className={styles.inputSpan4}
                value={address?.BusinessPhoneNumber}
                disabled
              />
              <TextInputWithPrefix
                name="email"
                prefix="E-Mail"
                className={styles.inputSpan8}
                value={address?.Emailaddress}
                disabled
              />
            </div>
            <TextInputWithPrefix
              name="VARTABatterieTestCheckProgramm"
              prefix="GVL VARTA"
              value={address?.VARTABatterieTestCheckProgramm}
              disabled
            />
          </div>
        </form>
      </div>
      <div className={styles.mapWrapper}>
        <iframe
          tabIndex={0}
          width="100%"
          height="100%"
          aria-hidden="false"
          style={{ border: 0 }}
          loading="lazy"
          src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=${address?.geoCoords.latitude}, ${address?.geoCoords.longitude}`}
        ></iframe>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          label="Protokolle anzeigen"
          className={styles.button}
          onClick={() => {
            linkTo('visit/logs', id);
          }}
        />
      </div>
    </ContainerWrapper>
  );
};

export default ViewShop;
