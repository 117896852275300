import React, { createContext, useEffect, useState } from 'react';

import { VisitLogType } from 'types/visitlog';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from 'services/firebase';
import { UserType } from 'types/user';

type ContextProps = {
  loadingMainState?: boolean;
  visitLogItems: VisitLogType[];
  users: UserType[];
};

type Props = {
  children: React.ReactNode;
};

export const MainContext = createContext<ContextProps>({
  loadingMainState: false,
  visitLogItems: [],
  users: [],
});

export const MainProvider = ({ children }: Props) => {
  const [visitLogItems, setVisitLogItems] = useState<VisitLogType[]>([]);
  const [users, setUsers] = useState<UserType[]>([]);

  useEffect(() => {
    const visitUnsubscribe = onSnapshot(collection(db, 'visit'), data => {
      setVisitLogItems(data.docs.map(item => item.data() as VisitLogType));
    });
    const userUnsubscribe = onSnapshot(collection(db, 'users'), data => {
      setUsers(data.docs.map(item => item.data() as UserType));
    });

    return () => {
      visitUnsubscribe();
      userUnsubscribe();
    };
  }, []);

  return (
    <MainContext.Provider
      value={{
        visitLogItems,
        users,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
