import React, { useContext, useEffect, useState } from 'react';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

import Table from 'components/Table';
import SelectOption from 'components/SelectOption';
import styles from './styles.module.scss';
import { db } from 'services/firebase';
import { OptionType } from 'types/global';
import { ScheduleType } from 'types/schedule';
import { Address } from 'types/address';
import { VisitLogType } from 'types/visitlog';
import { MainContext } from 'providers/Main';
import classNames from 'classnames';
import Button from 'components/Button';
import moment from 'moment';

const WeekOverview = () => {
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [assignedAddress, setAssignedAddress] = useState<Array<Address>>([]);
  const [visitedAddress, setVisitedAddress] = useState<Array<VisitLogType>>([]);
  const [userlist, setUserList] = useState<Array<OptionType>>([]);
  const [weekPlan, setWeekPlan] = useState<Array<ScheduleType>>([]);
  const [currentWeek, setCurrentWeek] = useState(moment().isoWeek());
  const currentYear = moment().year();
  const firstDayOfWeek = moment().isoWeekYear(currentYear).isoWeek(currentWeek).startOf('isoWeek');
  const lastDayOfWeek = moment().isoWeekYear(currentYear).isoWeek(currentWeek).endOf('isoWeek');
  const filteredSchedule = weekPlan.filter(elem => {
    if (!elem.date) {
      return false;
    }
    return moment(elem.date?.toDate()) >= firstDayOfWeek && moment(elem.date?.toDate()) <= lastDayOfWeek;
  });
  const daysOfWeek = [
    firstDayOfWeek,
    firstDayOfWeek.clone().add(1, 'days'),
    firstDayOfWeek.clone().add(2, 'days'),
    firstDayOfWeek.clone().add(3, 'days'),
    firstDayOfWeek.clone().add(4, 'days'),
  ];

  const { visitLogItems, users } = useContext(MainContext);

  const navigate = useNavigate();

  useEffect(() => {
    const promoter = users.filter(user => user.role === 'promoter');
    setUserList(
      promoter.map(item => {
        return {
          id: item.uid,
          label: `${item.firstName} ${item.lastName}`,
        };
      })
    );
    if (!promoter.length) {
      navigate('/agency/planning/address');
    }
    setSelectedUser(promoter[0].uid);
  }, []);

  useEffect(() => {
    const loadWeekPlan = async () => {
      const plan = await getDoc(doc(db, 'scheduled', selectedUser));
      setWeekPlan((plan.data()?.assigned as Array<ScheduleType>) ?? []);
    };
    const getAssignedAddress = async () => {
      const addresses = await getDocs(query(collection(db, 'address'), where('assigned', '==', selectedUser)));
      const documents = addresses.docs;
      setAssignedAddress(documents.map(add => add.data() as Address));
    };
    const getScheduledAddress = async () => {
      setVisitedAddress(visitLogItems.filter(item => item.userId === selectedUser));
    };
    if (selectedUser) {
      loadWeekPlan();
      getAssignedAddress();
      getScheduledAddress();
    }
  }, [selectedUser]);

  const changeSelectedUser = async (val: OptionType) => {
    setSelectedUser(val.id);
  };

  return selectedUser ? (
    <div className={styles.wrapper}>
      <div className={styles.headWrapper}>
        <SelectOption
          optionsArray={userlist}
          buttonClass="border-2 border-secondary px-4 py-2 rounded"
          direction="left"
          defaultValue={0}
          onChange={changeSelectedUser}
        />
        <p className={styles.overviewText}>
          Zugewiesene Adressen {assignedAddress.length} | Verfügbare Adressen{' '}
          {assignedAddress.filter(add => !add.complete).length} | In Planung {weekPlan.length} | Besuchsprotokolle{' '}
          {visitedAddress.length}
        </p>
      </div>
      <div className={styles.tableWrapper}>
        <Table
          className={classNames(styles.table)}
          title={
            <div className="flex gap-20 pb-10">
              <p>KW {currentWeek}</p>
              <Button
                label="zur aktuellen Woche"
                className=" font-univers-std text-18 text-white bg-primary px-20 pt-10 pb-5"
                onClick={() => setCurrentWeek(moment().isoWeek())}
              />
            </div>
          }
          head={[
            `Montag${daysOfWeek[0].format(', D.M.')}`,
            `Dienstag${daysOfWeek[1].format(', D.M.')}`,
            `Mittwoch${daysOfWeek[2].format(', D.M.')}`,
            `Donnerstag${daysOfWeek[3].format(', D.M.')}`,
            `Freitag${daysOfWeek[4].format(', D.M.')}`,
          ]}
        >
          <tr className={classNames(styles.tableButtonWrapper, styles.tableButtonLeft)}>
            <td>
              <button className={styles.tableButton} onClick={() => setCurrentWeek(currentWeek - 1)}>
                <svg
                  id="Gruppe_10"
                  data-name="Gruppe 10"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.77"
                  height="36"
                  viewBox="0 0 16.77 36"
                >
                  <g id="Gruppe_6" data-name="Gruppe 6" transform="translate(0 0)">
                    <path
                      id="Pfad_4"
                      data-name="Pfad 4"
                      d="M16.77,0l-7.7,18.144L16.77,36H10.636L0,18.144,10.636,0Z"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </button>
            </td>
          </tr>
          <tr className={classNames(styles.tableButtonWrapper, styles.tableButtonRight)}>
            <td>
              <button className={styles.tableButton} onClick={() => setCurrentWeek(currentWeek + 1)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16.77" height="36" viewBox="0 0 16.77 36">
                  <g id="Gruppe_9" data-name="Gruppe 9" transform="translate(0 120)">
                    <g id="Gruppe_6" data-name="Gruppe 6" transform="translate(0 -120)">
                      <path
                        id="Pfad_4"
                        data-name="Pfad 4"
                        d="M0,0,7.7,18.144,0,36H6.134L16.77,18.144,6.134,0Z"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </button>
            </td>
          </tr>
          <tr>
            {daysOfWeek.map((day, indextd) => (
              <td valign="top" key={day.toString()} className={styles.td}>
                <div className={classNames(styles.elementWrapper, { [styles.first]: indextd === 0 })}>
                  {filteredSchedule
                    ?.filter(sche => {
                      if (!sche.date) {
                        return false;
                      }
                      return day.isSame(moment(sche.date.toDate()), 'days');
                    })
                    ?.map((item, index) => (
                      <div className={styles.element} key={index}>
                        <p className={styles.text}>{item.address.CompanyName}</p>
                        <p className={classNames(styles.text, styles.smallText)}>{item.address.Address1}</p>
                        <p
                          className={classNames(styles.text, styles.smallText)}
                        >{`${item.address.PostalCode} ${item.address.City}`}</p>
                      </div>
                    ))}
                </div>
              </td>
            ))}
          </tr>
        </Table>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default WeekOverview;
