import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AddressViewer from 'pages/Main/Promoter/Planning/Address';
import PromoterWeekOverView from 'pages/Main/Promoter/Planning/WeekOverview';
import ViewShop from 'pages/Main/Promoter/Planning/ViewShop';
import VisitRecord from 'pages/Main/Promoter/Planning/VisitRecord';
import AllVisitLogs from 'pages/Main/Promoter/Planning/VisitRecord/All';
import DetailVisitLogs from 'pages/Main/Promoter/Planning/VisitRecord/Detail';

const PromoterRoute: React.FC = () => (
  <Routes>
    <Route path="/planning/address" element={<AddressViewer />}></Route>
    <Route path="/planning/weekoverview" element={<PromoterWeekOverView />}></Route>
    <Route path="/planning/shop/:id" element={<ViewShop />}></Route>
    <Route path="/planning/visit/logs/:id" element={<AllVisitLogs />}></Route>
    <Route path="/planning/visit/logs/details/:id" element={<DetailVisitLogs />}></Route>
    <Route path="/planning/visit/:route/:id" element={<VisitRecord />}></Route>
    <Route path="/*" element={<Navigate replace to="/" />} />
  </Routes>
);

export default PromoterRoute;
