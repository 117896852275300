import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import styles from './styles.module.scss';

type Props = {
  sliders: Array<React.ReactElement>;
};

const SliderWrapper: React.FC<Props> = ({ sliders = [] }) => {
  return (
    <div className={styles.wrapper}>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        modules={[Navigation]}
        navigation={true}
        pagination={{ clickable: true }}
        className={styles.swiperClass}
      >
        {sliders.map((slider, index) => (
          <SwiperSlide className={styles.swiperSlideClass} key={index}>
            <div className={styles.swiperSlideContents}>{slider}</div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SliderWrapper;
