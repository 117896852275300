import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// import { AuthContext } from 'providers/AuthProvider';
import AuthLayout from 'layouts/AuthLayout';
import LoginPage from 'pages/Auth/Login';
import { AuthContext } from 'providers/Auth';
import ResetPassword from 'pages/Auth/ResetPassword';

const AuthRoute: React.FC = () => {
  const { user } = useContext(AuthContext);

  if (user) {
    return <Navigate replace to="/" />;
  }

  return (
    <AuthLayout>
      <Routes>
        <Route path="login" element={<LoginPage />}></Route>
        <Route path="reset-password" element={<ResetPassword />}></Route>
        <Route path="/*" element={<Navigate replace to="login" />} />
      </Routes>
    </AuthLayout>
  );
};

export default AuthRoute;
