import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import LogoImg from 'assets/images/brand.png';
import Button from 'components/Button';
import { AuthContext } from 'providers/Auth';
import { buttonsList } from 'services/welcome';
import styles from './styles.module.scss';

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);

  const buttons = userData && buttonsList[userData.role];

  const linkTo = (link: string, service: string) => {
    if (service === 'Registrierung') {
      window.open(link, '_blank');
      return;
    }
    navigate(link);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.contents}>
        <img src={LogoImg} className={styles.logoImage} />
        <div className={styles.footerButtonGroup}>
          {buttons?.map(button => (
            <div key={button.url}>
              <Button
                label={button.label}
                className={classNames(styles.footerButton, { [styles.active]: location?.pathname === button.url })}
                labelClassName={styles.buttonLabel}
                onClick={() => {
                  linkTo(button.url, button.label);
                }}
              />
              <button
                onClick={() => {
                  linkTo(button.url, button.label);
                }}
                className={styles.link}
              >
                {button.label}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
