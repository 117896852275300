import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';

import ContainerWrapper from 'components/ContainerWrapper';
import { VisitLogType } from 'types/visitlog';
import { db } from 'services/firebase';
import styles from './styles.module.scss';
import moment from 'moment';
import DatePickerInput from 'components/DatePickerInput';
import TextArea from 'components/TextArea';
import DatePicker from 'react-datepicker';
import Button from 'components/Button';

const DetailVisitLogs = () => {
  const [visitLog, setVisitLog] = useState<VisitLogType | undefined>(undefined);
  const navigate = useNavigate();
  const { id } = useParams();
  const docRef = doc(db, 'visit', id!);

  useEffect(() => {
    const getVisitedLog = async () => {
      const documents = await getDoc(docRef);
      setVisitLog(documents.data() as VisitLogType);
    };
    getVisitedLog();
  }, []);

  return (
    <ContainerWrapper className={styles.wrapper}>
      <div className={styles.headWrapper}>
        <span className={styles.title}>Besuchsprotokoll</span>
      </div>
      <div className={styles.bodyWrapper}>
        <div className={styles.formWrapper}>
          {/* <div className={styles.overlay} /> */}
          <div className={styles.mainWrapper}>
            <h2 className="font-bold">{visitLog?.address.CompanyName}</h2>
            <p className="opacity-50 -mt-6">
              {moment(visitLog?.visitedAt.date).format('DD.MM.YYYY')}{' '}
              {moment(visitLog?.visitedAt.time, 'hh:mm A').format('HH:mm')} Uhr
            </p>
            <div className={styles.visitedAtLine}>
              <div className={styles.radioWrapper}>
                <input
                  checked={visitLog?.isVisited === 'VISITED' ? true : false}
                  type="radio"
                  id="isVisited"
                  name="visit"
                  required
                  readOnly
                ></input>
                <label htmlFor="isVisited">Besuch fand statt</label>
              </div>

              <div className={styles.radioWrapper}>
                <input
                  checked={visitLog?.isVisited !== 'VISITED' ? true : false}
                  type="radio"
                  id="isNotVisited"
                  name="visit"
                  required
                  readOnly
                ></input>
                <label htmlFor="isNotVisited">Besuch fand nicht statt</label>
              </div>
            </div>

            {/*----------------------------- IS NOT VISITED -------------------------------*/}

            {visitLog?.isVisited !== 'VISITED' && (
              <>
                <select disabled required className={styles.selectField} name="reasons" id="reasonNotVisited">
                  <option value={visitLog?.isVisitedReason}>
                    {visitLog?.isVisitedReason === 'postponed'
                      ? 'Besuch musste verschoben werden'
                      : visitLog?.isVisitedReason === 'closed'
                      ? 'Werkstatt dauerhaft geschlossen'
                      : visitLog?.isVisitedReason === 'nointerest'
                      ? 'Werkstatt hat kein Interesse'
                      : null}
                  </option>
                </select>
                {visitLog?.isVisitedReason === 'postponed' && (
                  <div className={styles.inputBox}>
                    <DatePicker
                      showMonthDropdown
                      selected={visitLog?.newAppointment ? new Date(visitLog?.newAppointment) : new Date()}
                      minDate={new Date()}
                      onChange={date => console.log(date)}
                      maxDate={new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)}
                      dateFormat="dd.MM.yyyy"
                      readOnly
                      className="w-full"
                      customInput={<DatePickerInput type="text" prefix="Neuer Termin" className={styles.datepicker} />}
                    />
                  </div>
                )}
              </>
            )}

            {/*----------------------------- IS VISITED -------------------------------*/}
            {visitLog?.isVisited === 'VISITED' && (
              <>
                <div className="flex gap-10">
                  Gesprächspartner:
                  <div className={styles.isVisitedLine}>
                    <p>{visitLog?.interlocutorFirstName}</p>
                    <p>{visitLog?.interlocutorLastName}</p>
                  </div>
                </div>

                {/*----------------------------- IS VARTA Partner Portal -------------------------------*/}
                <div className={styles.registeredBrandLine}>
                  <span className={styles.text}>Werkstatt vor Ort registriert für VARTA Partner Portal</span>

                  <div className="flex gap-9">
                    <div className={styles.radioWrapper}>
                      <input
                        readOnly
                        type="radio"
                        checked={visitLog?.registeredWorkShopVPP === 'isRegisterd' ? true : false}
                        id="isRegisterd"
                        name="registerd"
                        required
                      ></input>
                      <label htmlFor="isRegisterd">Ja</label>
                    </div>

                    <div className={styles.radioWrapper}>
                      <input
                        readOnly
                        type="radio"
                        id="isNotRegisterd"
                        name="registerd"
                        checked={
                          visitLog?.registeredWorkShopVPP === 'isNotRegisterd'
                            ? true
                            : false || visitLog?.isVisited !== 'VISITED'
                            ? true
                            : false
                        }
                        required
                      ></input>
                      <label htmlFor="isNotRegisterd">Nein</label>
                    </div>
                  </div>
                </div>
                {visitLog?.registeredWorkShopVPP === 'isNotRegisterd' && (
                  <select
                    disabled
                    required
                    className={styles.selectField}
                    name="reasonsRegisterd"
                    id="reasonsRegisterd"
                  >
                    <option value={visitLog?.registeredWorkShopVPPReasons}>
                      {visitLog?.registeredWorkShopVPPReasons === 'deciderNotPresent'
                        ? 'Entscheider nicht vor Ort'
                        : visitLog?.registeredWorkShopVPPReasons === 'isAlreadyRegistered'
                        ? 'Ist bereits registriert'
                        : visitLog?.registeredWorkShopVPPReasons === 'registersLater'
                        ? 'Registriert sich später'
                        : visitLog?.registeredWorkShopVPPReasons === 'differentPlatform'
                        ? 'Mitglied in einem anderen Werkstattkonzept / Nutzt andere Plattform'
                        : visitLog?.registeredWorkShopVPPReasons === 'noInterest'
                        ? 'Kein Interesse'
                        : null}
                    </option>
                  </select>
                )}
                {/*----------------------------- Kontakt durch GVL -------------------------------*/}
                <div className={styles.registeredBrandLine}>
                  <span className={styles.text}>Werkstatt wünscht Kontakt durch GVL</span>
                  <div className="flex gap-9">
                    <div className={styles.radioWrapper}>
                      <input
                        type="radio"
                        checked={visitLog?.registeredWorkShopGVL === 'isGVL' ? true : false}
                        id="isGVL"
                        name="GVL"
                        readOnly
                        required
                      ></input>
                      <label htmlFor="isGVL">Ja</label>
                    </div>

                    <div className={styles.radioWrapper}>
                      <input
                        type="radio"
                        id="isNotGVL"
                        name="GVL"
                        readOnly
                        checked={
                          visitLog?.registeredWorkShopGVL === 'isNotGVL'
                            ? true
                            : false || visitLog?.isVisited !== 'VISITED'
                            ? true
                            : false
                        }
                        required
                      ></input>
                      <label htmlFor="isNotGVL">Nein</label>
                    </div>
                  </div>
                </div>
                {visitLog?.registeredWorkShopGVL === 'isGVL' && (
                  <div>
                    <p>Kontaktdaten:</p>
                    <p>{visitLog?.registeredWorkShopGVLEmail}</p>
                    <p>{visitLog?.registeredWorkShopGVLPhone}</p>
                  </div>
                )}

                <TextArea
                  name="additionalInformation"
                  defaultValue={visitLog?.additionalInformation}
                  label="Sonstige Anmerkungen"
                  readOnly
                  rows={3}
                  required={false}
                />
              </>
            )}
            <Button
              label="Zurück"
              color="secondary"
              className="py-10 z-20"
              onClick={() => navigate(`/promoter/planning/visit/logs/${visitLog?.address.docId}`)}
            />
          </div>
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default DetailVisitLogs;
