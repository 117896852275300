import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Timestamp, addDoc, collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { notify } from 'services/notify';
import Button from 'components/Button';
import ContainerWrapper from 'components/ContainerWrapper';
import DatePickerInput from 'components/DatePickerInput';
import TextArea from 'components/TextArea';
import styles from './styles.module.scss';
import { Address } from 'types/address';
import { AuthContext } from 'providers/Auth';
import { VisitLogType } from 'types/visitlog';
import { db } from 'services/firebase';
import TextInput from 'components/TextInput';
import moment from 'moment';
import { UserType } from 'types/user';

type ScheduleType = {
  address: Address;
  day: string;
  date: Date;
};

const MainQuestion: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    isVisited: Yup.string().required('Pflichtfeld'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      isVisited: 'VISITED',
    },
  };

  const { userData } = useContext(AuthContext);
  const { register, handleSubmit, setValue, watch } = useForm(formOptions);
  const watchAll = watch();

  const [shopData, setShopData] = useState<Address>();
  const getNextWorkingDate = () => {
    const today = new Date();
    let day = today.getDay();

    // Increment the day until the next working day
    do {
      today.setDate(today.getDate() + 1);
      day = today.getDay();
    } while (day === 0 || day === 6); // 0 is Sunday and 6 is Saturday

    return today;
  };
  const [newAppointment, setNewAppointment] = useState<Date>(getNextWorkingDate());
  const [storedSchedule, setStoredSchedule] = useState<Array<ScheduleType>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isVisited, setIsVisited] = useState('');
  const [isRegisterd, setIsRegisterd] = useState('');
  const [isGVL, setIsGVL] = useState('');

  const visitTime = moment().format('hh:mm A');
  const visitDate = new Date();

  useEffect(() => {
    const getShop = async () => {
      if (!id) {
        navigate('/promoter/planning/weekoverview');
      } else {
        try {
          const shopDoc = await getDoc(doc(db, 'address', id));
          const data = shopDoc.data() as Address;
          if (data.complete && data.successful) {
            navigate('/promoter/planning/weekoverview');
          }
          setShopData({ ...data, docId: shopDoc.id });
        } catch (error) {
          navigate('/promoter/planning/weekoverview');
        }
      }
    };

    getShop();
  }, []);

  useEffect(() => {
    const getStoredSchedule = async () => {
      const documents = await getDoc(doc(db, 'scheduled', (userData as UserType).uid));
      const data = (documents.data()?.assigned as Array<ScheduleType>) ?? [];
      setStoredSchedule(data);
    };
    getStoredSchedule();
  }, []);

  const addSelectedToSchedule = async (date: Date) => {
    if (shopData) {
      // You're working with shopData in MainQuestion instead of address in the previous component
      const existingScheduleIndex = storedSchedule.findIndex(sche => sche.address.docId === shopData?.docId);
      const scheduleExist = storedSchedule.filter(sche => sche.address.docId === shopData?.docId).length > 0;
      setIsLoading(true);
      if (scheduleExist) {
        // Update the date of the existing schedule
        storedSchedule[existingScheduleIndex].date = date;
        await setDoc(doc(db, 'scheduled', `${(userData as UserType).uid}`), {
          assigned: storedSchedule,
        });
      } else {
        await setDoc(doc(db, 'scheduled', `${(userData as UserType).uid}`), {
          assigned: [...storedSchedule, { date: date, address: shopData }],
        });
      }
      setIsLoading(false);
    } else {
      notify('No Address to assign');
    }
  };

  const onSubmit = handleSubmit(async data => {
    let submitData: VisitLogType | undefined = undefined;

    if (data.isVisited === 'NOTVISITED') {
      submitData = {
        visitedAt: {
          date: moment(visitDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
          time: visitTime,
        },
        isVisited: data.isVisited,
        isVisitedReason: data.isVisitedReason,
        additionalInformation: data.additionalInformation,
        newAppointment:
          data.isVisitedReason === 'postponed' ? moment(newAppointment, 'DD.MM.YYYY').format('YYYY-MM-DD') : '',
        userId: userData?.uid as string,
        address: shopData!,
        createdAt: Date(),
      };
    } else {
      submitData = {
        visitedAt: {
          date: moment(visitDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
          time: visitTime,
        },
        isVisited: data.isVisited,
        interlocutorFirstName: data.interlocutorFirstName,
        interlocutorLastName: data.interlocutorLastName,
        registeredWorkShopVPP: data.registeredWorkShopVPP,
        registeredWorkShopVPPReasons: data.registeredWorkShopVPPReasons ?? '',
        registeredWorkShopGVL: data.registeredWorkShopGVL,
        registeredWorkShopGVLEmail: data.registeredWorkShopGVLEmail ?? '',
        registeredWorkShopGVLPhone: data.registeredWorkShopGVLPhone ?? '',
        additionalInformation: data.additionalInformation,
        userId: userData?.uid as string,
        address: shopData!,
        createdAt: Date(),
      };
    }

    try {
      await addDoc(collection(db, 'visit'), {
        ...submitData,
      });
      if (submitData?.isVisited == 'VISITED') {
        //
        // await updateDoc(doc(db, 'scheduled', (userData as UserType).uid), {
        //    assigned: [...scheduledAdd.filter(item => item.address.docId !== id)],
        // });
        if (submitData.registeredWorkShopVPP === 'isRegisterd') {
          await updateDoc(doc(db, 'address', id as string), { complete: true, successful: true });
        } else {
          await updateDoc(doc(db, 'address', id as string), { complete: true, successful: false });
        }
        const scheduledDocRef = doc(db, 'scheduled', (userData as UserType).uid);

        const scheduledSnapshot = await getDoc(scheduledDocRef);
        if (scheduledSnapshot.exists()) {
          const scheduledData = scheduledSnapshot.data();

          const updatedAssigned = scheduledData?.assigned.map((address: any) => {
            if (address.address.docId === shopData?.docId) {
              return {
                ...address,
                address: {
                  ...address.address,
                  complete: submitData?.isVisited === 'VISITED',
                  successful: submitData?.registeredWorkShopVPP === 'isRegisterd',
                },
              };
            }
            return address;
          });

          await updateDoc(scheduledDocRef, {
            assigned: updatedAssigned,
          });
        }
      } else if (submitData.isVisitedReason === 'postponed') {
        await addSelectedToSchedule(newAppointment);
      }
      navigate(`/promoter/planning/address`);
    } catch (e) {
      console.error(e);
      notify('Please enter the basic info!');
    }
  });

  return (
    <ContainerWrapper className={styles.wrapper}>
      <div className={styles.headWrapper}>
        <span className={styles.title}>Besuchsprotokoll</span>
      </div>
      <div className={styles.bodyWrapper}>
        <div className={styles.formWrapper}>
          <form onSubmit={onSubmit}>
            <div className={styles.mainWrapper}>
              <h2 className="font-bold">{shopData?.CompanyName}</h2>
              <p className="opacity-50">{visitDate.toLocaleDateString()}</p>
              <div className={styles.visitedAtLine}>
                <div className={styles.radioWrapper}>
                  <input
                    type="radio"
                    id="isVisited"
                    name="visit"
                    onChange={event => {
                      if (event.target.checked) {
                        setIsVisited('isVisited');
                        setValue('isVisited', 'VISITED');
                      }
                    }}
                    required
                  ></input>
                  <label htmlFor="isVisited">Besuch fand statt</label>
                </div>

                <div className={styles.radioWrapper}>
                  <input
                    type="radio"
                    id="isNotVisited"
                    name="visit"
                    onChange={event => {
                      if (event.target.checked) {
                        setIsVisited('isNotVisited');
                        setValue('isVisited', 'NOTVISITED');
                      }
                    }}
                    required
                  ></input>
                  <label htmlFor="isNotVisited">Besuch fand nicht statt</label>
                </div>
              </div>

              {/*----------------------------- IS NOT VISITED -------------------------------*/}

              {isVisited === 'isNotVisited' && (
                <>
                  {/* <label htmlFor="reasonNotVisited">Choose a pet:</label> */}

                  <select
                    required
                    className={styles.selectField}
                    name="reasons"
                    id="reasonNotVisited"
                    onChange={event => {
                      setValue('isVisitedReason', event.target.value);
                    }}
                  >
                    <option value="">--Warum nicht?--</option>
                    <option value="postponed">Besuch musste verschoben werden</option>
                    <option value="closed">Werkstatt dauerhaft geschlossen</option>
                    <option value="nointerest">Werkstatt hat kein Interesse</option>
                  </select>

                  {watchAll.isVisitedReason === 'postponed' && (
                    <div className={styles.inputBox}>
                      <DatePicker
                        selected={newAppointment}
                        onChange={(val: Date) => {
                          setNewAppointment(val);
                        }}
                        showMonthDropdown
                        minDate={getNextWorkingDate()}
                        dateFormat="dd.MM.yyyy"
                        onFocus={e => (e.target.readOnly = true)}
                        className="w-full"
                        customInput={
                          <DatePickerInput type="text" prefix="Neuer Termin" className={styles.datepicker} />
                        }
                      />
                    </div>
                  )}

                  <TextArea
                    name="additionalInformation"
                    label="Sonstige Anmerkungen"
                    register={register}
                    rows={3}
                    required={false}
                  />
                </>
              )}

              {/*----------------------------- IS VISITED -------------------------------*/}

              {isVisited === 'isVisited' && (
                <>
                  <div className="flex gap-10">
                    Gesprächspartner:
                    <div className={styles.isVisitedLine}>
                      <TextInput
                        className={styles.textInput}
                        variableName="interlocutorFirstName"
                        placeHolder="Vorname des Ansprechpartners"
                        type="text"
                        register={register}
                        required={true}
                      />
                      <TextInput
                        className={styles.textInput}
                        variableName="interlocutorLastName"
                        placeHolder="Nachname des Ansprechpartners"
                        type="text"
                        register={register}
                        required={true}
                      />
                    </div>
                  </div>

                  {/*----------------------------- IS VARTA Partner Portal -------------------------------*/}
                  <div className={styles.registeredBrandLine}>
                    <span className={styles.text}>Werkstatt vor Ort registriert für VARTA Partner Portal</span>

                    <div className="flex gap-9">
                      <div className={styles.radioWrapper}>
                        <input
                          type="radio"
                          id="isRegisterd"
                          name="registerd"
                          onChange={event => {
                            if (event.target.checked) {
                              setIsRegisterd('isRegisterd');
                              setValue('registeredWorkShopVPP', 'isRegisterd');
                            }
                          }}
                          required
                        ></input>
                        <label htmlFor="isRegisterd">Ja</label>
                      </div>

                      <div className={styles.radioWrapper}>
                        <input
                          type="radio"
                          id="isNotRegisterd"
                          name="registerd"
                          onChange={event => {
                            if (event.target.checked) {
                              setIsRegisterd('isNotRegisterd');
                              setValue('registeredWorkShopVPP', 'isNotRegisterd');
                            }
                          }}
                          required
                        ></input>
                        <label htmlFor="isNotRegisterd">Nein</label>
                      </div>
                    </div>
                  </div>
                  {isRegisterd === 'isNotRegisterd' && (
                    <>
                      <select
                        required
                        className={styles.selectField}
                        name="reasonsRegisterd"
                        id="reasonsRegisterd"
                        onChange={event => {
                          setValue('registeredWorkShopVPPReasons', event.target.value);
                        }}
                      >
                        <option value="">--Warum nicht?--</option>
                        <option value="deciderNotPresent">Entscheider nicht vor Ort</option>
                        <option value="isAlreadyRegistered">Ist bereits registriert</option>
                        <option value="registersLater">Registrtiert sich später</option>
                        <option value="differentPlatform">
                          Mitglied in einem anderen Werkstattkonzept / Nutzt andere Plattform
                        </option>
                        <option value="noInterest">Kein Interesse</option>
                      </select>
                    </>
                  )}

                  {/*----------------------------- Kontakt durch GVL -------------------------------*/}
                  <div className={styles.registeredBrandLine}>
                    <span className={styles.text}>Werkstatt wünscht Kontakt durch GVL</span>

                    <div className="flex gap-9">
                      <div className={styles.radioWrapper}>
                        <input
                          type="radio"
                          id="isGVL"
                          name="GVL"
                          onChange={event => {
                            if (event.target.checked) {
                              setIsGVL('isGVL');
                              setValue('registeredWorkShopGVL', 'isGVL');
                            }
                          }}
                          required
                        ></input>
                        <label htmlFor="isGVL">Ja</label>
                      </div>

                      <div className={styles.radioWrapper}>
                        <input
                          type="radio"
                          id="isNotGVL"
                          name="GVL"
                          onChange={event => {
                            if (event.target.checked) {
                              setIsGVL('isNotGVL');
                              setValue('registeredWorkShopGVL', 'isNotGVL');
                            }
                          }}
                          required
                        ></input>
                        <label htmlFor="isNotGVL">Nein</label>
                      </div>
                    </div>
                  </div>
                  {isGVL === 'isGVL' && (
                    <div>
                      <TextInput
                        register={register}
                        placeHolder="E-Mail"
                        variableName="registeredWorkShopGVLEmail"
                        type="text"
                        className={styles.textInput}
                      />
                      <TextInput
                        register={register}
                        placeHolder="Telefon"
                        variableName="registeredWorkShopGVLPhone"
                        type="text"
                        className={styles.textInput}
                      />
                    </div>
                  )}

                  <TextArea
                    name="additionalInformation"
                    label="Sonstige Anmerkungen"
                    register={register}
                    rows={3}
                    required={false}
                  />
                </>
              )}
              <div className={styles.buttonsWrapper}>
                <Button className={styles.button} color="secondary" label="Speichern" type="submit" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default MainQuestion;
