import React, { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';

import DropdownIcon from 'assets/images/arrow-down.svg';
import { OptionType } from 'types/global';
import styles from './styles.module.scss';

type Props = {
  buttonClass?: string;
  defaultValue: number;
  direction?: 'top' | 'bottom' | 'right' | 'left';
  optionsArray: Array<OptionType>;
  onChange?: (val: OptionType) => void;
};

const SelectOption: React.FC<Props> = ({
  optionsArray = [],
  onChange = () => {},
  defaultValue = 0,
  direction = 'left',
  buttonClass = '',
}) => {
  const [selectedOption, setSelectedOption] = useState<OptionType>(optionsArray[defaultValue]);
  const update = (val: OptionType) => {
    setSelectedOption(val);
    onChange(val);
  };

  return (
    <Listbox value={selectedOption} onChange={update}>
      {({ open }) => (
        <>
          <div className={styles.wrapper}>
            <Listbox.Button className={classNames(buttonClass, styles.buttonWrapper)}>
              <img src={DropdownIcon} className="w-auto h-3" />
              <span className={styles.buttonLabel}>{selectedOption?.label}</span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave={styles.transitionLeave}
              leaveFrom={styles.transitionLeaveFrom}
              leaveTo={styles.transitionLeaveTo}
            >
              <Listbox.Options
                className={classNames(
                  styles.listBox,
                  { [styles.right]: direction === 'right' },
                  { [styles.left]: direction === 'left' },
                  { [styles.bottom]: direction === 'bottom' },
                  { [styles.top]: direction === 'top' }
                )}
              >
                {optionsArray.map(item => (
                  <Listbox.Option
                    key={item?.id}
                    className={({ active }) =>
                      classNames(styles.listOption, { [styles.active]: active }, { [styles.adjective]: !active })
                    }
                    value={item}
                  >
                    <span className={styles.optionLabel}>{item?.label}</span>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default SelectOption;
