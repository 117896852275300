const emailRegexp = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const dateRegexp = new RegExp(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/);

const timeRegexp = new RegExp(/^(0?[1-9]|1[012]):[0-5][0-9]$/);

export const emailChecker = (email: string): boolean => emailRegexp.test(email);
export const dateChecker = (date: string): boolean => dateRegexp.test(date);
export const timeChecker = (time: string): boolean => timeRegexp.test(time);
