import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AddressManagement from 'pages/Main/Agency/Planning/Address';
import WeekOverview from 'pages/Main/Agency/Planning/WeekOverview';
import ViewShop from 'pages/Main/Agency/Planning/ViewShop';
import AllVisitLogs from 'pages/Main/Agency/Planning/VisitLogs';

const AgencyRoute: React.FC = () => (
  <Routes>
    <Route path="/planning/address" element={<AddressManagement />} />
    <Route path="/planning/weekoverview" element={<WeekOverview />} />
    <Route path="/planning/shop/:id" element={<ViewShop />} />
    <Route path="/planning/visit/logs/:id" element={<AllVisitLogs />} />
    <Route path="/*" element={<Navigate replace to="/" />} />
  </Routes>
);

export default AgencyRoute;
