import React, { useContext } from 'react';
import Button from 'components/Button';
import styles from './styles.module.scss';
import Wrapper from 'components/Wrapper';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'providers/Auth';
import classNames from 'classnames';
import { buttonsList } from 'services/welcome';

const Welcome = () => {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  const buttons = userData && buttonsList[userData.role];
  const start = (url: string, service: string) => {
    if (service === 'Registrierung') {
      window.open(url, '_blank');
      return;
    }
    navigate(url);
  };

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.contents, userData?.role === 'promoter' ? styles.grid4 : styles.grid3)}>
        {buttons?.map(button => (
          <Wrapper className={styles.wrapperComponent} key={button.url}>
            <div className={styles.headline}>{button.label}</div>
            <Button
              label={button.title}
              className={styles.welcomeButton}
              labelClassName={styles.buttonLabel}
              onClick={() => {
                start(button.url, button.label);
              }}
            />
          </Wrapper>
        ))}
      </div>
    </div>
  );
};

export default Welcome;
