import React, { useState, useEffect, useRef, useContext } from 'react';
import { AuthContext } from 'providers/Auth';
import { signOut } from 'firebase/auth';
import { auth } from 'services/firebase';
import styles from './styles.module.scss';

const UserDropDown = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isSpan, setIsSpan] = useState(false);
  const { userData } = useContext(AuthContext);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (isSpan && ref.current && !ref.current.contains(e.target)) {
        setIsSpan(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isSpan]);

  const logout = () => {
    signOut(auth);
  };

  return userData ? (
    <div className={styles.wrapper} ref={ref}>
      {!isSpan ? (
        <div>
          <button
            className={styles.dropdownButton}
            onClick={() => {
              setIsSpan(true);
            }}
          >
            <span className={styles.nameSpan}>
              {`${userData?.firstName?.charAt(0)}${userData?.lastName?.charAt(0)}`}
            </span>
          </button>
          <div className={styles.dotWrapper}>...</div>
        </div>
      ) : (
        <div className={styles.dropdownMenu}>
          <p className={styles.nameSpan}>{`${userData?.firstName} ${userData?.lastName}`}</p>
          <p className={styles.mailSpan}>{userData?.email}</p>
          <p className={styles.mailSpan}>v.2.2 (30.08.23)</p>
          <button className={styles.mailSpan} onClick={logout}>
            Logout
          </button>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default UserDropDown;
