import React, { forwardRef } from 'react';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  onChange?: (value: any) => void;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  prefix: string;
  type?: string;
  value?: any;
};

const DatePickerInput: React.FC<Props> = forwardRef<any, Props>(
  ({ className = '', onChange = () => {}, onClick = () => {}, prefix = '', type = 'text', value }, ref) => (
    <div className={className}>
      <div className={styles.contents}>
        <div className={styles.prefixWrapper}>{prefix}:</div>
        <div className={styles.inputWrapper}>
          <input
            className={styles.input}
            type={type}
            value={value}
            onChange={e => {
              onChange(e);
            }}
            onClick={onClick}
          />
        </div>
      </div>
    </div>
  )
);

export default DatePickerInput;
