import { Dialog, Transition } from '@headlessui/react';
import { MainContext } from 'providers/Main';
import { Fragment, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './style.module.scss';
import Button from 'components/Button';
import DatePicker from 'react-datepicker';

type UserInteractionsProps = {
  openModal: boolean;
  setOpenModal: (val: boolean) => void;
  selectedItem: any;
  showCheckmark?: boolean;
  addSelectedToSchedule?: (date: Date) => Promise<void> | undefined;
  visitDate?: Date;
  setVisitDate?: (val: Date) => void | undefined;
  isAgency: boolean;
};

const UserInteractions: React.FC<UserInteractionsProps> = ({
  openModal,
  setOpenModal,
  selectedItem,
  showCheckmark,
  addSelectedToSchedule,
  visitDate,
  setVisitDate,
  isAgency = false,
}) => {
  const { visitLogItems } = useContext(MainContext);
  const naviage = useNavigate();
  return (
    <Transition show={openModal} as={Fragment}>
      {selectedItem && (
        <Dialog as="div" className={styles.dialog} onClose={() => setOpenModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30" onClick={() => setOpenModal(false)} />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel>
              <div className={styles.panel}>
                <div className="w-full">
                  <div className={styles.closeBtn} onClick={() => setOpenModal(false)}>
                    X
                  </div>
                  <h2 className={styles.headline}>{selectedItem.CompanyName}</h2>
                  <div className={styles.additionalInformation}>
                    <p>{selectedItem.Address1}</p>
                    <p>
                      {selectedItem.PostalCode} {selectedItem.City}
                    </p>
                  </div>
                  <div className={styles.interactions}>
                    {!selectedItem.complete && isAgency === false && (
                      <div className={styles.datepickerWrapper}>
                        <form>
                          <DatePicker
                            selected={visitDate}
                            preventOpenOnFocus
                            onChange={(val: Date) => {
                              if (setVisitDate) {
                                setVisitDate(val);
                              }
                              if (addSelectedToSchedule) {
                                addSelectedToSchedule(val);
                              }
                            }}
                            showMonthDropdown
                            onFocus={e => (e.target.readOnly = true)}
                            placeholderText="Datum für Termin auswählen"
                            dateFormat="dd.MM.yyyy"
                            className={styles.datepicker}
                          />
                        </form>
                        {showCheckmark && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            color="#fff"
                            width={24}
                            height={16}
                            viewBox="0 0 512 512"
                            className={styles.checkmark}
                          >
                            <path
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="32"
                              d="M416 128 192 384l-96-96"
                            />
                          </svg>
                        )}
                      </div>
                    )}
                    {!selectedItem.complete && isAgency === false && (
                      <button
                        className={styles.addProtocol}
                        onClick={() => {
                          naviage(`/promoter/planning/visit/create/${selectedItem.docId}`);
                        }}
                      >
                        <span className={styles.addIcon}>+</span>
                        Neues Protokoll erstellen
                      </button>
                    )}
                    <div className="flex gap-x-30 max-w-full">
                      {(visitLogItems.filter(item => item.address?.id === selectedItem?.id).length > 0 ||
                        selectedItem.complete) && (
                        <Button
                          label="Protokolle ansehen"
                          className={styles.modalBtn}
                          onClick={() => {
                            if (isAgency === false) {
                              naviage(`/promoter/planning/visit/logs/${selectedItem.docId}`);
                            } else {
                              naviage(`/agency/planning/visit/logs/${selectedItem.docId}`);
                            }
                          }}
                        />
                      )}
                      <Button
                        label="Stammdaten"
                        className={styles.modalBtn}
                        onClick={() => {
                          if (isAgency === false) {
                            naviage(`/promoter/planning/shop/${selectedItem.docId}`);
                          } else {
                            naviage(`/agency/planning/shop/${selectedItem.docId}`);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      )}
    </Transition>
  );
};

export default UserInteractions;
