import styles from './styles.module.scss';
import Button from 'components/Button';

const PresentationSlider5 = () => {
  return (
    <div className="flex relative justify-center items-center">
      <div className={styles.wrapper}>
        <img src="/CL-14833_Promotion_App_Praesentation_DE_2308145.png" />
        <div className={styles.buttonWrapper}>
          <a href="https://info.varta-partner-portal.com/de-de/promoter-23" target="_blank">
            <Button
              label="Schnell anmelden"
              color="secondary"
              className={styles.button}
              labelClassName={styles.label}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PresentationSlider5;
