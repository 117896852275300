import { ButtonType } from 'types/global';

type ButtonListType = {
  agency: Array<ButtonType>;
  promoter: Array<ButtonType>;
  admin: Array<ButtonType>;
};
const buttonsList: ButtonListType = {
  agency: [
    { label: 'Planung', title: 'START', url: '/agency/planning/address' },
    { label: 'Kundenpräsentation', title: 'START', url: '/presentation' },
    { label: 'Wochenübersicht', title: 'START', url: '/agency/planning/weekoverview' },
  ],
  promoter: [
    { label: 'Planung', title: 'START', url: '/promoter/planning/address' },
    { label: 'Wochenübersicht', title: 'START', url: '/promoter/planning/weekoverview' },
    { label: 'Kundenpräsentation', title: 'START', url: '/presentation' },
    {
      label: 'Registrierung',
      title: 'ANMELDEN',
      url: 'https://info.varta-partner-portal.com/de-de/promoter-23',
    },
  ],
  admin: [
    { label: 'Statistiken', title: 'START', url: '/admin/statistics' },
    { label: 'Kundenpräsentation', title: 'START', url: '/presentation' },
    {
      label: 'Registrierung',
      title: 'ANMELDEN',
      url: 'https://info.varta-partner-portal.com/de-de/promoter-23',
    },
    // { label: 'User Management', title: 'START', url: '/admin/users' },
  ],
};

export { buttonsList };
