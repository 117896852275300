import React from 'react';
import SliderWrapper from 'components/SliderWrapper';
import styles from './styles.module.scss';
import PresentationSlider1 from './Slider/Slider1';
import PresentationSlider2 from './Slider/Slider2';
import PresentationSlider3 from './Slider/Slider3';
import PresentationSlider4 from './Slider/Slider4';
import PresentationSlider5 from './Slider/Slider5';
import PresentationSlider6 from './Slider/Slider6';

const Presentation = () => {
  return (
    <div className={styles.wrapper}>
      <SliderWrapper
        sliders={[
          <PresentationSlider1 />,
          <PresentationSlider2 />,
          <PresentationSlider3 />,
          <PresentationSlider4 />,
          <PresentationSlider5 />,
          <PresentationSlider6 />,
        ]}
      ></SliderWrapper>
    </div>
  );
};

export default Presentation;
