import Statistics from 'pages/Main/Admin/Statistics';
import UserManagement from 'pages/Main/Admin/Users';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const AdminRoute: React.FC = () => (
  <Routes>
    <Route path="/users" element={<UserManagement />} />
    <Route path="/statistics" element={<Statistics />} />
    <Route path="/*" element={<Navigate replace to="/" />} />
  </Routes>
);

export default AdminRoute;
