import React from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';

import styles from './styles.module.scss';

type Props = {
  name: string;
  register?: UseFormRegister<FieldValues>;
  required: boolean;
  label?: string;
  defaultValue?: string;
  rows?: number;
  onChange?: (value: any) => void;
  readOnly?: boolean;
};

const TextArea: React.FC<Props> = ({
  rows = 3,
  label,
  name = '',
  required = false,
  register,
  readOnly = false,
  defaultValue,
  onChange = () => {},
}) => (
  <div className={styles.wrapper}>
    <label className={styles.label} htmlFor={name}>
      {label}
    </label>
    <textarea
      rows={rows}
      defaultValue={defaultValue}
      readOnly={readOnly}
      className={styles.textarea}
      {...(register && register(name, { required: required }))}
      onChange={e => {
        onChange(e.target.value);
      }}
    ></textarea>
  </div>
);

export default TextArea;
