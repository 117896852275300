import React, { useContext, useState } from 'react';
import { updateDoc, doc } from 'firebase/firestore';

import Button from 'components/Button';
import Table from 'components/Table';
import SelectOption from 'components/SelectOption';
import { db } from 'services/firebase';
import { OptionType } from 'types/global';
import styles from './styles.module.scss';
import { notify } from 'services/notify';
import { MainContext } from 'providers/Main';

type NeedUpdates = {
  uid: string;
  role: string;
};

const UserManagement = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { users } = useContext(MainContext);
  const [needUpdates, setNeedUpdates] = useState<Array<NeedUpdates>>([]);
  const userRols = [
    { id: 'promoter', label: 'Promoter' },
    { id: 'agency', label: 'Agency' },
    { id: 'admin', label: 'Admin' },
  ];

  const updateUserData = (uid: string, role: string) => {
    setNeedUpdates(prev => [...prev.filter(item => item.uid !== uid), { uid: uid, role: role }]);
  };

  const saveUpdateToDB = async () => {
    try {
      if (needUpdates.length) {
        setIsLoading(true);
        await Promise.all(
          needUpdates.map(async item => {
            await updateDoc(doc(db, 'users', item.uid), { role: item.role });
          })
        );
        setIsLoading(false);
        notify('Updated');
      } else {
        notify('No Change');
      }
    } catch (error) {
      notify('Faild');
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.tableWrapper}>
          <Table head={['Vorname', 'Name', 'E-Mail', 'Usergruppe']} title="User Management">
            {users.map(user => (
              <tr key={user.uid}>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.email}</td>
                <td>
                  <div>
                    <SelectOption
                      defaultValue={userRols.findIndex(item => {
                        return item.id === user.role;
                      })}
                      optionsArray={userRols}
                      onChange={(val: OptionType) => {
                        updateUserData(user.uid, val.id);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </Table>
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            label="Speichern"
            className={styles.button}
            onClick={saveUpdateToDB}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
