import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datepicker/dist/react-datepicker.css';

import Button from 'components/Button';
import ContainerWrapper from 'components/ContainerWrapper';
import DatePickerInput from 'components/DatePickerInput';
import DistanceSelector from 'components/DistanceSelector';
import GoolgePlaceAutoComplete from 'components/GooglePlaceAutoComplete';
import Table from 'components/Table';
import styles from './styles.module.scss';
import { GeoCodeType } from 'types/global';
import { MainContext } from 'providers/Main';
import { VisitLogType } from 'types/visitlog';
import { calculateDistance } from 'services/googlemap';
import SideTab from 'components/SideTab';
import classNames from 'classnames';

const Statistics = () => {
  const [visitRecords, setVisitRecords] = useState<VisitLogType[]>([]);
  const [filteredRecords, setFilteredRecords] = useState<VisitLogType[]>([]);
  const [selectedDistance, setSelectedDistance] = useState<number>(150);
  const [ortGeocode, setOrtGeocode] = useState<GeoCodeType>({
    latitude: 0,
    longitude: 0,
    name: '',
  });
  const [isSideTabOpen, setIsSideTabOpen] = useState(false);
  const [from, setFrom] = useState<string>(moment('01.08.2023', 'DD.MM.YYYY').format('DD.MM.YYYY'));
  const [to, setTo] = useState<string>(moment(new Date()).format('DD.MM.YYYY'));

  const { visitLogItems, users } = useContext(MainContext);

  const toggleSideTab = () => {
    setIsSideTabOpen(!isSideTabOpen);
  };

  const filter = async () => {
    if (from && to) {
      setFilteredRecords(
        visitRecords.filter(
          record =>
            moment(record?.visitedAt?.date, 'YYYY-MM-DD').isAfter(moment(from, 'DD.MM.YYYY').subtract(1, 'days')) &&
            moment(record?.visitedAt?.date, 'YYYY-MM-DD').isBefore(moment(to, 'DD.MM.YYYY').add(1, 'days'))
        )
      );
    }

    if (ortGeocode.latitude || ortGeocode.longitude) {
      setFilteredRecords(
        filteredRecords.filter(
          record => calculateDistance(record?.address?.geoCoords, ortGeocode) < selectedDistance * 1000
        )
      );
    }
  };

  useEffect(() => {
    setVisitRecords(visitLogItems);
  }, []);

  useEffect(() => {
    if (visitRecords && visitRecords.length > 0) {
      setFilteredRecords(
        visitRecords.filter(
          record =>
            moment(record?.visitedAt?.date, 'YYYY-MM-DD').toDate() >= moment(from, 'DD.MM.YYYY').toDate() &&
            moment(record?.visitedAt?.date, 'YYYY-MM-DD').toDate() <= moment(to, 'DD.MM.YYYY').toDate()
        )
      );
    }
  }, [visitRecords]);

  const formatReportsForCSV = () => {
    const formattedItems: { [key: string]: VisitLogType[] } = {};
    filteredRecords
      .sort((a, b) =>
        moment(`${a?.visitedAt?.date} ${a?.visitedAt?.time}`, ['YYYY-MM-DD HH:mm', 'YYYY-MM-DD hh:mm A']).isAfter(
          moment(`${b?.visitedAt?.date} ${b?.visitedAt?.time}`, ['YYYY-MM-DD HH:mm', 'YYYY-MM-DD hh:mm A'])
        )
          ? 1
          : -1
      )
      .forEach(item => {
        if (formattedItems[item?.address?.docId] && formattedItems[item?.address?.docId].length > 0) {
          formattedItems[item?.address?.docId].push(item);
        } else {
          formattedItems[item?.address?.docId] = [item];
        }
      });

    const csvData = Object.keys(formattedItems).map(item => formattedItems[item][formattedItems[item].length - 1]);

    return csvData.map(item => {
      const user = users.find(userItem => userItem?.uid === item?.userId);
      console.log(csvData);
      return {
        'Compay Name': item?.address?.CompanyName,
        'Address 1': item?.address?.Address1,
        'Address 2': item?.address?.Address2,
        City: item?.address?.City,
        State: item?.address?.State,
        Country: item?.address?.Country,
        'VARTA GVL Vorname': '',
        'VARTA GVL Nachname': '',
        'ZIP/Postal': item?.address?.PostalCode,
        'Email address': item?.address?.Emailaddress,
        'Business Phone Number': item?.address?.BusinessPhoneNumber,
        'Anzahl Mitarbeiter': item?.address?.NumberOfEmployees,
        'Besuch fand statt': item?.isVisited === 'VISITED' ? 'ja' : 'nein',
        'Besuch musste verschoben werden':
          item?.isVisited === 'NOTVISITED' && item?.isVisitedReason === 'postponed' ? 'ja' : '',
        'Neuer Termin': item?.newAppointment ? moment(item?.newAppointment, 'YYYY-MM-DD').format('DD.MM.YYYY') : '',
        'Werkstatt hat kein Interesse':
          item?.isVisited === 'NOTVISITED' && item?.isVisitedReason === 'nointerest' ? 'ja' : '',
        'Werkstatt ist geschlossen': item?.isVisited === 'NOTVISITED' && item?.isVisitedReason === 'closed' ? 'ja' : '',
        'Gesprächspartner Vorname': item?.interlocutorFirstName,
        'Gesprächspartner Nachname': item?.interlocutorLastName,
        'Anzahl Hebebühnen': item?.address?.NumberOfPlatforms,
        'VARTA Partner Portal': item?.registeredWorkShopVPP === 'isRegisterd' ? 'ja' : 'nein',
        'VARTA Partner Portal Info':
          item?.registeredWorkShopVPP === 'isNotRegisterd' && item?.registeredWorkShopVPPReasons === 'deciderNotPresent'
            ? 'Entscheider nicht vor Ort'
            : item?.registeredWorkShopVPP === 'isNotRegisterd' &&
              item?.registeredWorkShopVPPReasons === 'isAlreadyRegistered'
            ? 'Ist bereits registriert'
            : item?.registeredWorkShopVPP === 'isNotRegisterd' &&
              item?.registeredWorkShopVPPReasons === 'registersLater'
            ? 'Registriert sich später'
            : item?.registeredWorkShopVPP === 'isNotRegisterd' &&
              item?.registeredWorkShopVPPReasons === 'differentPlatform'
            ? 'Mitglied in einem anderen Werkstattkonzept / Nutzt andere Plattform'
            : item?.registeredWorkShopVPP === 'isNotRegisterd' && item?.registeredWorkShopVPPReasons === 'noInterest'
            ? 'Kein Interesse'
            : '',
        'Werkstatt wünscht Kontakt durch GVL': item?.registeredWorkShopGVL === 'isGVL' ? 'ja' : 'nein',
        'Kontakt GVL E-Mail':
          item?.registeredWorkShopGVL === 'isGVL' && item?.registeredWorkShopGVLEmail
            ? item?.registeredWorkShopGVLEmail
            : '',
        'Kontakt GVL Telefon':
          item?.registeredWorkShopGVL === 'isGVL' && item?.registeredWorkShopGVLPhone
            ? item?.registeredWorkShopGVLPhone
            : '',
        'Weitere Info': item?.additionalInformation,
        'Daten aktualisiert': item?.address.updatedByPromoter ? 'ja' : 'nein',

        Promoter: user ? `${user?.firstName} ${user?.lastName}`.trim() : '',
        Datum: moment(item?.visitedAt?.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
      };
    });
  };

  const clearAllFilters = () => {
    setSelectedDistance(150); // Default distance
    setOrtGeocode({
      latitude: 0,
      longitude: 0,
      name: '',
    }); // Default geocode
    setFrom(moment('01.08.2023', 'DD.MM.YYYY').format('DD.MM.YYYY')); // Default from date
    setTo(moment(new Date()).format('DD.MM.YYYY')); // Default to date
    filter(); // Filter the records
    // Add any other filter-related states that need to be reset
  };

  return (
    <div className="overflow-x-hidden">
      <SideTab isOpen={isSideTabOpen}>
        <div className={styles.sidebarContentWrapper}>
          <span className={styles.title}>Besuchte Werkstätten im Umkreis:</span>
          <div className={styles.filterWrapper}>
            <div className={styles.distanceSelectorWrapper}>
              <div className={styles.filterContents}>
                <div className={styles.distanceFilter}>
                  <p>Kilometer:</p>
                  <DistanceSelector
                    selected={selectedDistance}
                    disabled={false}
                    selectItem={val => {
                      setSelectedDistance(val);
                    }}
                  />
                </div>
                <GoolgePlaceAutoComplete
                  value={ortGeocode.name}
                  onChange={val => {
                    setOrtGeocode(val);
                  }}
                />
              </div>
            </div>
            <div>
              <div className={styles.timeFilterWrapper}>
                <span className={styles.title}>Besuchte Werktstätten im Zeitraum:</span>
                <div className={styles.filterContents}>
                  <div className="flex gap-20">
                    <DatePicker
                      value={from}
                      onChange={(val: Date) => {
                        setFrom(moment(val).format('DD.MM.YYYY'));
                      }}
                      showMonthDropdown
                      className={styles.datePicker}
                      dateFormat="dd.MM.yyyy"
                      customInput={<DatePickerInput type="text" prefix="Von" />}
                    />
                    <DatePicker
                      value={to}
                      onChange={(val: Date) => {
                        setTo(moment(val).format('DD.MM.YYYY'));
                      }}
                      showMonthDropdown
                      className={styles.datePicker}
                      dateFormat="dd.MM.yyyy"
                      customInput={<DatePickerInput type="text" prefix="Bis" />}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Button
              label="Filtern"
              className={styles.filterButton}
              onClick={() => {
                filter();
                setIsSideTabOpen(false);
              }}
            />
          </div>
        </div>
      </SideTab>
      <ContainerWrapper className={classNames(styles.wrapper, { [styles.shifted]: isSideTabOpen })}>
        <div className={classNames(styles.filterTab, { [styles.shifted]: isSideTabOpen })}>
          <div className={styles.buttonsWrapper}>
            <div className={styles.buttonsWrapperStart}>
              <Button
                label={isSideTabOpen ? 'Schließen' : 'Filter'}
                onClick={toggleSideTab}
                className={styles.filterToggleButton}
                icon={isSideTabOpen ? 'Cross' : 'Filter'}
                labelClassName="flex justify-center items-center gap-[8px]"
              />
              <Button
                label="Alle Filter zurücksetzen"
                onClick={clearAllFilters}
                className={styles.removeFilterButton}
                labelClassName="flex justify-center items-center gap-[8px]"
              />
            </div>
          </div>
        </div>
        <div className={styles.body}>
          <Table
            className="mb-50"
            head={[
              'Besuchte Werkstätten',
              'Gespräch fand statt',
              'Werkstatt hat kein Interesse',
              'Werkstatt dauerhaft geschlossen',
              'Besuch musste verschoben werden',
              'Registrierung vor Ort VPP',
              'Kontakt durch GVL',
            ]}
            title="Ergebnis"
          >
            <tr>
              <td>{filteredRecords.length}</td>
              <td>{filteredRecords.filter(item => item.isVisited === 'VISITED').length}</td>
              <td>
                {
                  filteredRecords
                    .filter(item => item.isVisited === 'NOTVISITED')
                    .filter(item => item.isVisitedReason === 'nointerest').length
                }
              </td>
              <td>
                {
                  filteredRecords
                    .filter(item => item.isVisited === 'NOTVISITED')
                    .filter(item => item.isVisitedReason === 'closed').length
                }
              </td>
              <td>
                {
                  filteredRecords
                    .filter(item => item.isVisited === 'NOTVISITED')
                    .filter(item => item.isVisitedReason === 'postponed').length
                }
              </td>
              <td>
                {
                  filteredRecords
                    .filter(item => item.isVisited === 'VISITED')
                    .filter(item => item.registeredWorkShopVPP === 'isRegisterd').length
                }
              </td>
              <td>
                {
                  filteredRecords
                    .filter(item => item.isVisited === 'VISITED')
                    .filter(item => item.registeredWorkShopGVL === 'isGVL').length
                }
              </td>
            </tr>
          </Table>
        </div>
        <div className={styles.footer}>
          <CSVLink data={formatReportsForCSV()} filename="Promotion_Export.csv">
            <Button label="Als CSV exportieren" className={styles.button}></Button>
          </CSVLink>
        </div>
      </ContainerWrapper>
    </div>
  );
};

export default Statistics;
