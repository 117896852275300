import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import MainLayout from 'layouts/MainLayout';
import Welcome from 'pages/Main/Welcome';
import Presentation from 'pages/Main/Presentation';
import AgencyRoute from 'routes/AgencyRoute';
import PromoterRoute from 'routes/PromoterRoute';
import AdminRoute from 'routes/AdminRoute';
import PrivateRouteByRole from 'routes/PrivateRouteByRole';
import { MainProvider } from 'providers/Main';

const MainRoute: React.FC = () => (
  <MainLayout>
    <MainProvider>
      <Routes>
        <Route path="/" element={<Welcome />}></Route>
        <Route path="/presentation" element={<Presentation />}></Route>
        <Route
          path="/agency/*"
          element={
            <PrivateRouteByRole role="agency">
              <AgencyRoute />
            </PrivateRouteByRole>
          }
        ></Route>
        <Route
          path="/promoter/*"
          element={
            <PrivateRouteByRole role="promoter">
              <PromoterRoute />
            </PrivateRouteByRole>
          }
        ></Route>
        <Route
          path="/admin/*"
          element={
            <PrivateRouteByRole role="admin">
              <AdminRoute />
            </PrivateRouteByRole>
          }
        ></Route>
        <Route path="/*" element={<Navigate replace to="/" />} />
      </Routes>
    </MainProvider>
  </MainLayout>
);

export default MainRoute;
