import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete, Libraries, useLoadScript } from '@react-google-maps/api';

import styles from './styles.module.scss';
import { GeoCodeType } from 'types/global';

type Props = {
  onChange?: (val: GeoCodeType) => void;
  value: string;
};

const GoolgePlaceAutoComplete: React.FC<Props> = ({ onChange = () => {}, value }) => {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>();
  const [libraries] = useState<Libraries | undefined>(['places']);
  const ref = useRef<any>(null);

  useEffect(() => {
    if (value === '' && ref.current) {
      ref.current.value = '';
    }
  }, [value, ref]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    libraries,
    language: 'de',
  });

  const onLoad = (autocompletedata: google.maps.places.Autocomplete) => {
    setAutocomplete(autocompletedata);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const lat = autocomplete?.getPlace()?.geometry?.location?.lat();
      const lng = autocomplete?.getPlace()?.geometry?.location?.lng();
      onChange({
        latitude: lat ? (lat as number) : 0,
        longitude: lng ? (lng as number) : 0,
        name: autocomplete?.getPlace()?.name ?? '',
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputWrapper}>
        <p className="">Ort:</p>
        {isLoaded && (
          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} className="w-full">
            <input
              ref={ref}
              type="text"
              placeholder='z.B. "Berlin"'
              className={styles.input}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (!e.target.value) onChange({ latitude: 0, longitude: 0, name: '' });
              }}
            />
          </Autocomplete>
        )}
      </div>
    </div>
  );
};
export default GoolgePlaceAutoComplete;
