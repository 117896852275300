import React from 'react';
import styles from './styles.module.scss';
import VartaTag from '../../assets/images/varta_tag.png';

type Props = {
  children: React.ReactElement;
};

const AuthLayout: React.FC<Props> = ({ children }) => (
  <div className={styles.wrapper}>
    <img src={VartaTag} className={styles.bgImage} />
    <div className={styles.contents}>{children}</div>
  </div>
);

export default AuthLayout;
