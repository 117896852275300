import React, { useContext, useEffect, useState } from 'react';

import {
  collection,
  query,
  getDocs,
  doc,
  setDoc,
  orderBy,
  where,
  startAt,
  getDoc,
  Timestamp,
} from 'firebase/firestore';
import { useScrollToBottom } from 'use-scroll-to-bottom';

import Button from 'components/Button';
import Table from 'components/Table';
import DistanceSelector from 'components/DistanceSelector';
import TextInputWithPrefix from 'components/TextInputWithPrefix';
import GoolgePlaceAutoComplete from 'components/GooglePlaceAutoComplete';
import { db } from 'services/firebase';
import { Address } from 'types/address';
import styles from './styles.module.scss';
import { GeoCodeType } from 'types/global';
import { calculateDistance } from 'services/googlemap';
import { AuthContext } from 'providers/Auth';
import { UserType } from 'types/user';
import { notify } from 'services/notify';
import ContainerWrapper from 'components/ContainerWrapper';
import SideTab from 'components/SideTab';
import classNames from 'classnames';
import CheckBox from 'components/CheckBox';
import UserInteractions from 'components/UserInteractions';

type ScheduleType = {
  address: Address;
  day: string;
  date: Date;
};

type SortableColumn = 'employees' | 'platforms' | null;

const AddressViewer = () => {
  const [setBottomRef, isBottom] = useScrollToBottom();
  const { userData } = useContext(AuthContext);

  const [address, setAddress] = useState<Array<Address>>([]);
  const [fetchedEnd, setFetchedEnd] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastFetched, setLastFetched] = useState<number>(0);
  const [selectedDistance, setSelectedDistance] = useState<number>(150);
  const [shopName, setShopName] = useState<string>('');
  const [storedSchedule, setStoredSchedule] = useState<Array<ScheduleType>>([]);
  const [ortGeocode, setOrtGeocode] = useState<GeoCodeType>({
    latitude: 0,
    longitude: 0,
    name: '',
  });
  const [isSideTabOpen, setIsSideTabOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Address | null>(null);
  const [visitDate, setVisitDate] = useState<Date>('' as any);
  const [hideCompleted, setHideCompleted] = useState(false);
  const [showCheckmark, setShowCheckmark] = useState(false);
  const [activeSort, setActiveSort] = useState<string | null>(null);
  const [sortOrderEmployees, setSortOrderEmployees] = useState('default'); // "default", "asc", or "desc"
  const [sortOrderPlatforms, setSortOrderPlatforms] = useState('default'); // "default", "asc", or "desc"

  const toggleSideTab = () => {
    setIsSideTabOpen(!isSideTabOpen);
  };

  const loadMore = async () => {
    setIsFetching(true);
    try {
      const documents = await getDocs(
        query(collection(db, 'address'), orderBy('id'), startAt(lastFetched), where('assigned', '==', userData?.uid))
      );
      const data = documents.docs;
      const addressData = data.map(item => {
        return { ...(item.data() as Address), docId: item.id };
      });

      const filtered: Array<Address> = addressData
        .filter(item => {
          if (!hideCompleted) return true;
          return !(
            (!item.complete && item.successful) ||
            (item.complete && item.successful) ||
            (item.complete && !item.successful)
          );
        })
        .filter(item => {
          if (ortGeocode.latitude == 0 && ortGeocode.longitude == 0) return true;
          const distance = calculateDistance(item.geoCoords, ortGeocode);
          return distance < selectedDistance * 1000;
        })
        .filter(item => item.CompanyName.includes(shopName));

      setAddress(prev => [...prev, ...filtered]);
      setLastFetched(documents.docs[documents.docs.length - 1].data().id + 1);
    } catch (error) {
      setFetchedEnd(true);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    const getStoredSchedule = async () => {
      const documents = await getDoc(doc(db, 'scheduled', (userData as UserType).uid));
      const data = (documents.data()?.assigned as Array<ScheduleType>) ?? [];
      setStoredSchedule(data);
    };
    getStoredSchedule();
  }, []);

  useEffect(() => {
    if (isBottom && !isFetching) {
      loadMore();
    }
  }, [isBottom, lastFetched]);

  const filterBy = () => {
    setAddress([]);
    setLastFetched(0);
    setFetchedEnd(false);
  };

  const addSelectedToSchedule = async (date: Date) => {
    if (address.length) {
      const existingScheduleIndex = storedSchedule.findIndex(sche => sche.address.docId === selectedItem?.docId);
      const scheduleExist = storedSchedule.filter(sche => sche.address.docId === selectedItem?.docId).length > 0;
      setIsLoading(true);
      if (scheduleExist) {
        // Update the date of the existing schedule
        storedSchedule[existingScheduleIndex].date = date;
        await setDoc(doc(db, 'scheduled', `${(userData as UserType).uid}`), {
          assigned: storedSchedule,
        });
      } else {
        await setDoc(doc(db, 'scheduled', `${(userData as UserType).uid}`), {
          assigned: [...storedSchedule, { date: date, address: selectedItem }],
        });
      }
      setIsLoading(false);
      setShowCheckmark(true);
      setTimeout(() => setShowCheckmark(false), 2000); // hide after 2 seconds
    } else {
      notify('No Address to assign');
    }
  };

  const clearAllFilters = () => {
    setSelectedDistance(150); // Default distance
    setOrtGeocode({
      latitude: 0,
      longitude: 0,
      name: '',
    }); // Default geocode
    setShopName(''); // Clear the shop name
    setHideCompleted(false); // Clear the hide completed
    filterBy(); // Clear the address list
    // Add any other filter-related states that need to be reset
  };

  const getVisitStatus = (item: Address) => {
    if (item.complete && item.successful) {
      return 'visited';
    } else if (!item.complete && !item.successful) {
      return 'notVisited';
    } else if (item.complete && !item.successful) {
      return 'pending';
    }
    return null;
  };

  const getDateAssignedToItem = (docId: string): Date | null => {
    const item = storedSchedule.find(sche => sche.address.docId === docId);
    if (item && item.date) {
      const date = item.date instanceof Timestamp ? item.date.toDate() : item.date;
      return date instanceof Date ? date : null;
    }
    return null;
  };

  useEffect(() => {
    if (selectedItem) {
      const dateAssigned = getDateAssignedToItem(selectedItem.docId);
      if (dateAssigned) {
        setVisitDate(dateAssigned);
      } else {
        setVisitDate('' as any);
      }
    }
  }, [selectedItem, storedSchedule]);

  const formatDateWithYear = (date: Date): string => {
    const day = date.getDate().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 because getMonth() is 0-indexed
    const year = date.getFullYear().toString();
    return `${day}.${month}.${year}`;
  };

  const toggleSort = (column: SortableColumn) => {
    if (column === 'employees') {
      if (sortOrderEmployees === 'default') {
        setSortOrderEmployees('desc');
      } else if (sortOrderEmployees === 'desc') {
        setSortOrderEmployees('asc');
      } else {
        setSortOrderEmployees('default');
      }
    } else if (column === 'platforms') {
      if (sortOrderPlatforms === 'default') {
        setSortOrderPlatforms('desc');
      } else if (sortOrderPlatforms === 'desc') {
        setSortOrderPlatforms('asc');
      } else {
        setSortOrderPlatforms('default');
      }
    }
    setActiveSort(column);
  };

  const sortedAddresses = [...address].sort((a, b) => {
    if (activeSort === 'employees') {
      const numA = Number(a.NumberOfEmployees || 0);
      const numB = Number(b.NumberOfEmployees || 0);
      return sortOrderEmployees === 'asc'
        ? numA - numB
        : sortOrderEmployees === 'desc'
        ? numB - numA
        : sortOrderEmployees === 'default'
        ? 0
        : 0;
    } else if (activeSort === 'platforms') {
      const numA = Number(a.NumberOfPlatforms || 0);
      const numB = Number(b.NumberOfPlatforms || 0);
      return sortOrderPlatforms === 'asc'
        ? numA - numB
        : sortOrderPlatforms === 'desc'
        ? numB - numA
        : sortOrderPlatforms === 'default'
        ? 0
        : 0;
    } else if (activeSort === null) {
      return sortOrderEmployees === 'default' && sortOrderPlatforms === 'default' ? 0 : 0;
    }
    return 0;
  });

  return (
    <>
      <div className="overflow-x-hidden">
        <SideTab isOpen={isSideTabOpen}>
          <div className={styles.sidebarContentWrapper}>
            <p className={styles.title}>Umkreissuche</p>
            <div className={styles.filterWrapper}>
              <div className={styles.distanceSelectorWrapper}>
                <div className={styles.filterContents}>
                  <div className={styles.distanceFilter}>
                    <p>Kilometer:</p>
                    <DistanceSelector
                      selected={selectedDistance}
                      disabled={false}
                      selectItem={val => {
                        setSelectedDistance(val);
                      }}
                    />
                  </div>
                  <GoolgePlaceAutoComplete
                    value={ortGeocode.name}
                    onChange={val => {
                      setOrtGeocode(val);
                    }}
                  />
                </div>
              </div>
              <div className={styles.nameFilterWrapper}>
                <div className={styles.filterContents}>
                  <p className={styles.title}>Suche Werkstattname</p>
                  <TextInputWithPrefix
                    value={shopName}
                    prefix="Name"
                    name="nameforFilter"
                    placeholder="Geben Sie einen Werkstattnamen ein."
                    onChange={val => {
                      setShopName(val);
                    }}
                  />
                </div>
              </div>
              <CheckBox
                value={hideCompleted.toString()}
                size="small"
                label="bereits besuchte Werkstätten ausblenden"
                className="mt-4"
                checked={hideCompleted}
                onChange={() => setHideCompleted(!hideCompleted)}
              />
              <Button
                label="Filter anwenden"
                className={styles.filterButton}
                onClick={() => {
                  filterBy();
                  setIsSideTabOpen(false);
                }}
              />
            </div>
          </div>
        </SideTab>
        <ContainerWrapper className={classNames(styles.wrapper, { [styles.shifted]: isSideTabOpen })}>
          <div className={classNames(styles.filterTab, { [styles.shifted]: isSideTabOpen })}>
            <div className={styles.buttonsWrapper}>
              <div className={styles.buttonsWrapperStart}>
                <Button
                  label={isSideTabOpen ? 'Schließen' : 'Filter'}
                  onClick={toggleSideTab}
                  className={styles.filterToggleButton}
                  icon={isSideTabOpen ? 'Cross' : 'Filter'}
                  labelClassName="flex justify-center items-center gap-[8px]"
                />
                <Button
                  label="Alle Filter entfernen"
                  onClick={clearAllFilters}
                  className={styles.removeFilterButton}
                  labelClassName="flex justify-center items-center gap-[8px]"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-grow h-full">
            <div className={styles.tableWrapper}>
              <Table
                head={[
                  'Firma',
                  'Anschrift',
                  'Telefon',
                  <span onClick={() => toggleSort('employees')} className="flex">
                    Mitarbeiter{' '}
                    {activeSort === 'employees' && sortOrderEmployees === 'desc' ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256">
                        <path
                          fill="currentColor"
                          d="m213.66 101.66l-80 80a8 8 0 0 1-11.32 0l-80-80a8 8 0 0 1 11.32-11.32L128 164.69l74.34-74.35a8 8 0 0 1 11.32 11.32Z"
                        />
                      </svg>
                    ) : activeSort === 'employees' && sortOrderEmployees === 'asc' ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256">
                        <path
                          fill="currentColor"
                          d="M213.66 165.66a8 8 0 0 1-11.32 0L128 91.31l-74.34 74.35a8 8 0 0 1-11.32-11.32l80-80a8 8 0 0 1 11.32 0l80 80a8 8 0 0 1 0 11.32Z"
                        />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256">
                        <path
                          fill="currentColor"
                          d="M181.66 170.34a8 8 0 0 1 0 11.32l-48 48a8 8 0 0 1-11.32 0l-48-48a8 8 0 0 1 11.32-11.32L128 212.69l42.34-42.35a8 8 0 0 1 11.32 0Zm-96-84.68L128 43.31l42.34 42.35a8 8 0 0 0 11.32-11.32l-48-48a8 8 0 0 0-11.32 0l-48 48a8 8 0 0 0 11.32 11.32Z"
                        />
                      </svg>
                    )}
                  </span>,
                  <span onClick={() => toggleSort('platforms')} className="flex">
                    Hebebühnen{' '}
                    {activeSort === 'platforms' && sortOrderPlatforms === 'desc' ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256">
                        <path
                          fill="currentColor"
                          d="m213.66 101.66l-80 80a8 8 0 0 1-11.32 0l-80-80a8 8 0 0 1 11.32-11.32L128 164.69l74.34-74.35a8 8 0 0 1 11.32 11.32Z"
                        />
                      </svg>
                    ) : activeSort === 'platforms' && sortOrderPlatforms === 'asc' ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256">
                        <path
                          fill="currentColor"
                          d="M213.66 165.66a8 8 0 0 1-11.32 0L128 91.31l-74.34 74.35a8 8 0 0 1-11.32-11.32l80-80a8 8 0 0 1 11.32 0l80 80a8 8 0 0 1 0 11.32Z"
                        />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256">
                        <path
                          fill="currentColor"
                          d="M181.66 170.34a8 8 0 0 1 0 11.32l-48 48a8 8 0 0 1-11.32 0l-48-48a8 8 0 0 1 11.32-11.32L128 212.69l42.34-42.35a8 8 0 0 1 11.32 0Zm-96-84.68L128 43.31l42.34 42.35a8 8 0 0 0 11.32-11.32l-48-48a8 8 0 0 0-11.32 0l-48 48a8 8 0 0 0 11.32 11.32Z"
                        />
                      </svg>
                    )}
                  </span>,
                ]}
              >
                {sortedAddresses.map(item => (
                  <tr
                    key={item.id}
                    onClick={() => {
                      setOpenModal(true);
                      setSelectedItem(item);
                    }}
                  >
                    <td>{item.CompanyName}</td>
                    <td className="flex flex-col">
                      <span>
                        {item.Address1} {item.Address2}
                      </span>
                      <span>
                        {item.PostalCode} {item.City}
                      </span>
                    </td>
                    <td>{item.BusinessPhoneNumber}</td>
                    <td>{item.NumberOfEmployees === '' ? 0 : item.NumberOfEmployees}</td>
                    <td>{item.NumberOfPlatforms}</td>

                    <td>
                      <div
                        className={classNames(
                          'flex flex-grow flex-row justify-start items-center h-full',
                          getVisitStatus(item) === 'visited'
                            ? 'gap-x-5'
                            : getVisitStatus(item) === 'notVisited'
                            ? 'gap-x-5'
                            : getVisitStatus(item) === 'pending'
                            ? 'gap-x-5'
                            : ''
                        )}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={26} viewBox="0 0 24 24">
                          <g
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                          >
                            <rect width="18" height="18" x="3" y="4" rx="2" ry="2" />
                            <path d="M16 2v4M8 2v4m-5 4h18" />
                          </g>
                        </svg>
                        {(() => {
                          const status = getVisitStatus(item);
                          switch (status) {
                            case 'visited':
                              return (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    color="#12D931"
                                    width={24}
                                    height={16}
                                    viewBox="0 0 512 512"
                                  >
                                    <path
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="32"
                                      d="M416 128 192 384l-96-96"
                                    />
                                  </svg>
                                  <p className="border pt-[3px] px-5">VPP</p>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    color="#12D931"
                                    width={24}
                                    height={16}
                                    viewBox="0 0 512 512"
                                  >
                                    <path
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="32"
                                      d="M416 128 192 384l-96-96"
                                    />
                                  </svg>
                                </>
                              );

                            case 'notVisited':
                              if (getDateAssignedToItem(item.docId) && storedSchedule.length > 0) {
                                return (
                                  <p className="mt-[7px]">
                                    {formatDateWithYear(getDateAssignedToItem(item.docId) as Date)
                                      ? formatDateWithYear(getDateAssignedToItem(item.docId) as Date)
                                      : ''}
                                  </p>
                                );
                              }
                              break;

                            case 'pending':
                              return (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    color="#12D931"
                                    width={24}
                                    height={16}
                                    viewBox="0 0 512 512"
                                  >
                                    <path
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="32"
                                      d="M416 128 192 384l-96-96"
                                    />
                                  </svg>
                                  <p className="border pt-[3px] px-5">VPP</p>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    color="red"
                                    viewBox="0 0 256 256"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M205.66 194.34a8 8 0 0 1-11.32 11.32L128 139.31l-66.34 66.35a8 8 0 0 1-11.32-11.32L116.69 128L50.34 61.66a8 8 0 0 1 11.32-11.32L128 116.69l66.34-66.35a8 8 0 0 1 11.32 11.32L139.31 128Z"
                                    />
                                  </svg>
                                </>
                              );

                            default:
                              return undefined;
                          }
                        })()}
                      </div>
                    </td>
                  </tr>
                ))}
              </Table>
              {!fetchedEnd && (
                <div className="text-center text-white" ref={setBottomRef}>
                  Loading...{' '}
                  <svg
                    role="status"
                    className={styles.spinner}
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
        </ContainerWrapper>
      </div>
      <UserInteractions
        openModal={openModal}
        selectedItem={selectedItem}
        setOpenModal={setOpenModal}
        showCheckmark={showCheckmark}
        addSelectedToSchedule={addSelectedToSchedule}
        visitDate={visitDate}
        isAgency={false}
        setVisitDate={setVisitDate}
      />
    </>
  );
};

export default AddressViewer;
