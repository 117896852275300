import React from 'react';
import classNames from 'classnames';
import { UseFormRegister, FieldValues } from 'react-hook-form';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  errorMsg?: any;
  placeHolder?: string;
  register: UseFormRegister<FieldValues>;
  required?: boolean;
  type: string;
  validate?: any;
  variableName: string;
};

const TextInput: React.FC<Props> = ({
  className = '',
  errorMsg = '',
  placeHolder = '',
  register,
  required = false,
  type = 'text',
  validate,
  variableName = '',
}) => {
  return (
    <div className={styles.wrapper}>
      <input
        className={classNames(className, styles.inputBox)}
        placeholder={placeHolder}
        type={type}
        {...register(variableName, { required: required ? 'This field is required' : false, validate })}
      />
      {errorMsg && <div className={styles.errorMsg}>{errorMsg.message}</div>}
    </div>
  );
};

export default TextInput;
