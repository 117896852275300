import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UserDropDown from 'components/UserDropdown';
import Footer from 'layouts/Footer';
import { AuthContext } from 'providers/Auth';
import { buttonsList } from 'services/welcome';
import styles from './styles.module.scss';

type Props = {
  children: React.ReactElement;
};

const MainLayout: React.FC<Props> = ({ children }) => {
  const { userData } = useContext(AuthContext);
  const location = useLocation();
  const [buttonLabel, setButtonLabel] = useState('');

  useEffect(() => {
    if (userData) {
      const buttons = buttonsList[userData.role];
      const currentButton = buttons.find(button => button.url === location.pathname);
      if (currentButton) {
        setButtonLabel(currentButton.label);
      }
    }
  }, [location.pathname, userData]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.header}>{buttonLabel}</div>

        <div className={styles.contents}>{children}</div>

        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
      <div className={styles.userDropdown}>
        <UserDropDown />
      </div>
    </div>
  );
};

export default MainLayout;
