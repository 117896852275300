import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'providers/Auth';

type Props = {
  children: React.ReactElement;
  role: string;
};

export default function PrivateRouteByRole({ children, role }: Props) {
  const { userData } = useContext(AuthContext);

  if (!userData) {
    return null;
  }

  if (userData?.role !== role) {
    return <Navigate to="/" />;
  }

  return children;
}
