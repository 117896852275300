import { Navigate, useParams } from 'react-router-dom';

import MainQuestion from './Main';

const VisitRecord = () => {
  const { route } = useParams();

  if (route === 'create') {
    return <MainQuestion />;
  } else {
    return <Navigate replace to={'/promoter/planning/weekoverview'} />;
  }
};

export default VisitRecord;
