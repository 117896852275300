import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';

import PromoterClaim from 'assets/images/Promoter_Claim.png';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import Wrapper from 'components/Wrapper';
import styles from './styles.module.scss';
import { auth } from 'services/firebase';
import { emailChecker } from 'services/validate';
import { notify } from 'services/notify';

const Login = () => {
  const [showInput, setShowInput] = useState(false);
  const navigate = useNavigate();
  const [signInWithEmailAndPassword, user, loading, error] = useSignInWithEmailAndPassword(auth);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit(data => {
    signInWithEmailAndPassword(data.email, data.password);
  });

  useEffect(() => {
    if (!loading && user && !error) {
      navigate('/');
    }
    if (error) {
      notify('Wrong Password or Email');
    }
  }, [user, loading, error]);

  return (
    <div className={styles.wrapper}>
      {!showInput ? (
        <>
          <img src={PromoterClaim} className={styles.brandImage} />
          <div className={styles.contents}>
            <Button
              className={styles.loginButton}
              label="LOGIN"
              labelClassName={styles.buttonLabel}
              onClick={() => {
                setShowInput(true);
              }}
            />
          </div>
        </>
      ) : (
        <form onSubmit={onSubmit} className={styles.formControl}>
          <Wrapper className={styles.inputWrapper}>
            <TextInput
              type="text"
              className="text-center"
              variableName="email"
              errorMsg={errors.email}
              placeHolder="EMAIL"
              register={register}
              validate={(value: string) => {
                return emailChecker(value) || 'Please enter valid email address';
              }}
              required={true}
            />
            <TextInput
              className="text-center"
              errorMsg={errors.password}
              placeHolder="PASSWORT"
              register={register}
              required={true}
              type="password"
              variableName="password"
            />
            <Button
              className={styles.submitButton}
              isLoading={loading}
              label="Log In"
              labelClassName={styles.submitLabel}
              type="submit"
            />
            <div className={styles.linkWrapper}>
              <Link to="/auth/reset-password" className={styles.link}>
                Passwort vergessen?
              </Link>
            </div>
          </Wrapper>
        </form>
      )}
    </div>
  );
};

export default Login;
