import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthRoute from 'routes/AuthRoute';
import MainRoute from 'routes/MainRoute';
import PrivateRoute from 'routes/PrivateRoute';
import { AuthProvider } from 'providers/Auth';

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/auth/*" element={<AuthRoute />}></Route>
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <MainRoute />
            </PrivateRoute>
          }
        />
      </Routes>
      <ToastContainer />
    </AuthProvider>
  );
}

export default App;
