import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Wrapper: React.FC<Props> = ({ children, className = '' }) => (
  <div className={classNames(className, styles.wrapper)}> {children} </div>
);

export default Wrapper;
