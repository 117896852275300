import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'providers/Auth';

type Props = {
  children: React.ReactElement;
};

export default function PrivateRoute({ children }: Props) {
  const { user, loadingAuthState } = useContext(AuthContext);

  if (loadingAuthState) {
    return null;
  }

  if (!user) {
    return <Navigate to="/auth/login" />;
  }
  return children;
}
