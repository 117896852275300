import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

type Props = {
  head: Array<string | React.ReactNode>;
  children: React.ReactNode;
  title?: React.ReactNode;
  className?: string;
};

const Table: React.FC<Props> = ({ head = [], children, title = '', className = '' }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.tableWrapper}>
        <p className={styles.title}>{title}</p>
        <table className={classNames(className, styles.table)}>
          <thead>
            <tr>
              {head.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
