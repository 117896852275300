import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  disabled?: boolean;
  selected?: number;
  selectItem?: (val: number) => void;
};

const DistanceSelector: React.FC<Props> = ({ selected, selectItem = () => {}, disabled }) => {
  const distances: Array<number> = [0, 5, 25, 50, 75, 100, 125, 150];
  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.desktopView, { [styles.disabled]: disabled })}>
        {distances.map(item => (
          <button
            className={classNames(styles.selector, { [styles.selected]: selected === item })}
            key={item}
            disabled={disabled}
            onClick={() => selectItem(item)}
          >
            {item}
          </button>
        ))}
      </div>
      <div className={classNames(styles.mobileView, { [styles.disabled]: disabled })}>
        <select
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            selectItem(parseInt(event.target.value));
          }}
          className={styles.select}
        >
          {distances.map(item => (
            <option value={item} key={item}>
              {item}Km
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DistanceSelector;
